import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Box, AppBar } from '@mui/material';
import DesktopBar from './components/DesktopBar/DesktopBar';
import { useTranslation } from 'react-i18next';
import MobileBar from './components/MobileBar';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import GridOnIcon from '@mui/icons-material/GridOn';
import FeedIcon from '@mui/icons-material/Feed';
import GroupsIcon from '@mui/icons-material/Groups';
import { topBar } from 'api/sanity';
import LinkIcon from '@mui/icons-material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { ContentLogo, CardImg, CustomNavLink } from './styles/styles';
import BBVALogo from 'assets/logos/BBVA_logo.svg';
import PropTypes from 'prop-types';
import HomeIcon from '@mui/icons-material/Home';
import {  ButtonGoToBBVA } from './components/DesktopBar/styled';
import { BiLinkExternal } from 'react-icons/bi';

/**
 * It's a function that takes in an array of objects and returns a styled-component.
 * @param {Array} items - An array of objects that contains the name and path of the items.
 * @returns A component that renders a navbar with a logo and a list of items.
 */
const MenuResponsive = ({items,onlyMainLogo})=>{
    return (
        <AppBar position="static" 
            sx={{
                m:'0px 0px',
                p:'0px 0px',
                minHeight:'75px',
                maxHeight:'75px',
                backgroundColor:'transparent',
                boxShadow:'none'
            }}
        >
            <Box 
                sx={{
                    minHeight:'75px',
                    maxHeight:'75px',
                    m:'0px 0px',
                    p:'0px 0px',
                }}
            >
                <Toolbar disableGutters sx={{ minHeight:'75px',
                    maxHeight:'75px',m:'0px 0px',p:'0px 0px'}}>
                    <ContentLogo> 
                        <CustomNavLink to="/collection-get">
                            <CardImg
                                component="img"
                                src={BBVALogo}
                                alt="logo"
                                sx={{ ml:'-6px', width: '100%' }}
                            />
                        </CustomNavLink>
                    </ContentLogo>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml:'10px' }}>
                        {!onlyMainLogo && items.map((item,index) => (
                            <Button
                                key={index}
                                sx={{color: 'white', display: 'block',background:'transparent',"&:hover":{background:'transparent'}}}
                                component={CustomNavLink}
                                to={item.path}
                            >
                                {item.name}
                            </Button>
                        ))}
                        
                    </Box>
                    <ButtonGoToBBVA onClick={() => window.open("https://www.bbva.mx/", "_blank")} >
                        Ir a BBVA México
                        <BiLinkExternal size={20} style={{}} />
                    </ButtonGoToBBVA>
                </Toolbar>
            </Box>
        </AppBar>
    )
}

MenuResponsive.defaultProps = {
    onlyMainLogo:false
}

MenuResponsive.propTypes = {
    items: PropTypes.array,
    onlyMainLogo: PropTypes.bool
}

/**
 * It's a function that returns a fragment that contains a box that contains a container that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a box that contains
 * a box that contains a box that contains a box that contains a box that contains a
 * @returns The return is a component that is being rendered in the index.js file.
 */
const TopBar = () => {
    const { t } = useTranslation("translate");
    let initItems = [
        {
            name:t("top_bar.home"),
            path:'/home',
            visible:true,
            public:false,
            icon:HomeIcon
        },
        /* {
            name:t("top_bar.about"),
            path:'/home',
            visible:true,
            public:false
        } */
    ]
    const [items,setItems] = React.useState(initItems);
    React.useEffect(() => {
        setItems(initItems);
    },[t]);
    const { setOpenNav } = useContext(DrawerMobileContext);

    const handleOpenNav = () => {
        setOpenNav(true);
    }

    const validationIsPublicLink = (item) =>{
        let valid = false;
        if(item && !item.externalLink && !item.internalLink || item.externalLink && item.internalLink){
            return false;
        }else{
            if(item && item.externalLink){
                valid = true;
            }
        }
        return valid;
    }

    const validationTitleOrICon = (text,getIcon) =>{
        if(String(text).toUpperCase().includes(String(t('topbar.explore'))) || String(text).toUpperCase().includes("EXPLORE")){
            return !getIcon? t('topbar.explore'):GridOnIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.curators'))) || String(text).toUpperCase().includes("CURATORS")){
            return !getIcon ? t('topbar.curators'):GroupsIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.about'))) || String(text).toUpperCase().includes("ABOUT")){
            return !getIcon ? t('topbar.about'):FeedIcon
        }
        return !getIcon ? text:LinkIcon;
    }

    React.useEffect(() => {
        let temp = 22;
        if(temp == -22){
            topBar().then(response=>{
                try{
                    if(response[0] && response[0].menu){
                        let newArray = new Array();
                        response[0].menu?.map((item)=>{
                            newArray.push({
                                name: validationTitleOrICon(item.title,false),
                                icon: validationTitleOrICon(item.title,true),
                                path: item.link,
                                visible : true,
                                public: validationIsPublicLink(item)
                            })
                        })
                        setItems(newArray)
                    }
                }catch (e) {
                    console.error(e)
                }
            });
        }
    },[]);

    return (
        <React.Fragment>
            <Box
                sx={{
                    width:'100%',
                    minHeight:'75px',
                    maxHeight:'75px',
                    background: 'transparent'
                }}
            >
                <Container maxWidth="xl" sx={{height:'100%'}}>
                    <Box 
                        sx={{display:'flex',alignItems:'center',height:'75px'}}
                    >
                        <Box
                            sx={{
                                width:'100%',display:'flex',justifyContent:'space-between'
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    gap:'10px'
                                }}
                            >
                                <MenuResponsive items={items} onlyMainLogo={true} />
                            </Box>
                            <Box
                                sx={{
                                    display:'flex',
                                    height:'75px',
                                    justifyContent:'flex-end',
                                    alignItems:'center'
                                }}
                            >   
                                <DesktopBar tabs={items} />
                                <MobileBar tabs={items} />
                                <Box
                                    onClick={handleOpenNav}
                                    sx={{display: { sm: 'block', md: 'none' },color:'#D9D9D9',cursor:'pointer'}}
                                >
                                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3333 16.125H29.1146M14.3333 21.5H29.1146M14.3333 26.875H29.1146" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                        <rect x="1" y="1" width="41" height="41" rx="7" stroke="#fff" strokeWidth="2"/>
                                    </svg>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}

export default TopBar;
