import { styled } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

export const FooterContainer = styled('footer')`
    color: #fff;
`;

export const FooterDivider = styled(Divider)`
    //width:'100%', background:'#092F55',  marginBottom:'1rem'
    width: 100%;
    background: #092F55;
`;

export const FooterDividerSeparation = styled(Divider)`
    border: 1px solid #5BBEFF;
    height: 15px;
`;

export const FooterContent = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
        display:'grid',
        gridTemplateColumns:'repeat(1,1fr)'
    }
})); 

export const FooterContentLogo = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
        justifyContent:'center',
        marginBottom:'10px'
    }
})); 


export const FooterContentLinks = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
        justifyContent:'center'
    }
})); 



export const FooterSubtitle = styled(Box)`
    text-transform: capitalize;
    color: #2DCCCD;
    letter-spacing: initial;
    font-weight: 500;
    font-family: BentonSansBBVA, sans-serif;
`;

export const FooterLink = styled(Link)`
    text-decoration: none;
    color: #5BBEFF;
    font-weight: 100;
`;

export const FooterCopy = styled(Box)`
    font-family: BentonSansBBVA, sans-serif;
    text-align: left;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    color: #6D7B94;
    border:1px solid red;
    width: calc(99% - 170px);
    @media (max-width: 786px) {
        width: calc(99% - 170px);
        p {
            width: calc(99% - 170px);
        }
    },
    @media (max-width: 586px) {
        width: calc(99% - 170px);
        p {
            width: calc(99% - 170px);
        }
    },
    @media (max-width: 486px) {
        width: calc(99% - 170px);
        text-align: center;
        p {
            width: calc(99% - 170px);
            text-align: center;
        }
    }
    
`