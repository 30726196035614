
import React ,{useContext}from 'react';
import { CardMedia, Box, CardContent,Typography, Tooltip } from '@mui/material'
import {BigTitle, DisplayOver,BackgroundNewCard} from './styled';
import { StatusTx } from 'hooks/StatusTxContext';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';
import { isMinted } from 'services/ERC721/isMinted';
import { getThumbnail } from 'services/Thumbail/getThumbnail';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconBlockies from 'components/IconBlockies/IconBlockies';

const CardNFT = ({ item }) =>{
    console.log('item ::',item)
    const { statusTx } = useContext(StatusTx);
    const {t} = useTranslation("translate");
    return (
        <Link to={`/nft?address=${item.collection_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}>
            <BackgroundNewCard>
                {
                    item && item.metadata.is_video ?
                    <CardMedia
                        className='card-media'
                        component={'video'}
                        src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                        autoPlay
                        loop
                        muted
                        sx={{
                            position:'relative',
                            borderRadius: '8px 8px 0 0',
                            height:'100%',
                            width:'100%',
                            margin: '0 auto',
                        }}
                    />
                    :
                    <CardMedia
                        className='card-media'
                        component={'img'}
                        src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                        autoPlay
                        loop
                        muted
                        sx={{
                            borderRadius: '8px',
                            height:'100%',
                            width:'100%',
                            margin:'0 auto',
                            "&:hover":{
                                border:'5px solid #35F8FF'
                            }
                        }}
                    />
                }
                                

                <Box
                    sx={{
                        position:'absolute',
                        width:'100%',
                        height:'100%',
                        top:'0px',
                        left:'0px',
                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 25.53%, rgba(0, 0, 0, 0.5) 67.17%, #000000 109.78%)',
                    }}
                />
                
                
                <DisplayOver>
                    <BigTitle>
                        <Box
                            sx={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'flex-start'
                            }}
                        >
                            {
                                isMinted(item) && 
                                <Tooltip title={item.metadata.json_data.name+''}  placement="top">
                                    <Box
                                        sx={{
                                            width:'auto',
                                            boxSizing:'border-box',
                                            maxWidth:'100%'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:'100%',
                                                boxSizing:'border-box',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                fontWeight:600, 
                                                fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                                fontSize:'30px',
                                                color:'#FFF',
                                                backgroundColor:'rgba(0, 0, 0, 0.2)',
                                                padding:'0.5rem',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            {item.metadata.json_data.name}
                                        </Box>
                                    </Box>
                                </Tooltip>
                            }
                        </Box>
                    </BigTitle>
                </DisplayOver>
                <Box
                    sx={{
                        height: "auto",
                        bottom: "0",
                        left: "0",
                        position: "absolute",
                        width: "100%",
                        zIndex: 2,
                        backgroundColor: "transparent",
                        boxSizing: "border-box"
                    }}
                >
                    <CardContent
                        sx={{
                            borderRadius:'0 0 8px 8px',
                            height:'auto',
                            width:'100%',
                            display:'flex',
                            flexDirection:'column',
                            padding:'0px 15px',
                            margin:'0px'
    
                        }}
                    >
                        <Box
                            sx={{
                                display:'grid',
                                gridTemplateColumns:'1fr'
                            }}
                        >
                            
                            <Box
                                display='flex'
                                sx={{
                                    gap:'5px',
                                    width:'100%'
                                }}
                            >
                  
                                <Box component='span'>
                                    <IconBlockies address={item?.user?.main_key} />
                                </Box>
                                <Box component='span' sx={{display:'none'}}>
                                    <Typography 
                                        variant="overline" 
                                        display="block" 
                                        gutterBottom 
                                        component='span'
                                        sx={{
                                            marginTop:'3px',
                                            fontSize:'25px',
                                            color:'#FEFFFF',
                                            fontWeight: 'bold',
                                            cursor:'pointer',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '13rem',
                                            display:'inline-block',
                                            fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                            "@media (min-width: 320px)": {
                                                fontSize:'13px',
                                                fontWeight: 200,
                                            }
                                        }}
                                    >
                                       {
                                        item && item?.user && item?.user?.username && !Web3.utils.isAddress(item?.user?.username) ? 
                                        item?.user?.username
                                        :
                                        item?.user?.short_main_key
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Box>
                <Box
                    sx={{
                        position:'absolute',
                        bottom:'0px',
                        right:'0px',
                    }}
                >
                    <Box
                        sx={{
                            display:'none',
                            width:'auto',
                            justifyContent: 'flex-end',
                            padding:'15px',
                            fontWeight:'bold',
                            fontFamily:'BentonSansBBVA-Medium,sans-serif'
                        }}
                    >
                       {
                        !statusTx && String(item?.user.main_key+'').toUpperCase() == String(item.collection.user.main_key+'').toUpperCase() && t("collection.claim_text")
                       }
                    </Box>
                </Box>
            </BackgroundNewCard>
        </Link>
    );
};

CardNFT.propTypes = {
    item: PropTypes.object
}

export default CardNFT;
