import React, { useContext } from 'react'
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import { useTheme } from '@emotion/react';
import { Box, Divider, Drawer, IconButton, List, ListItem, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import { Context } from 'hooks/WalletContext';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LanguageIcon from '@mui/icons-material/Language';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

const MobileBar = ({tabs}) => {
    const { t } = useTranslation("translate")
    const { data } = useContext(Context);
    const { openNav, setOpenNav, setOpenWallet } = useContext(DrawerMobileContext);
    const theme = useTheme();
    const handleClickOpenWallet = () => {
        setOpenWallet(true);
        setOpenNav(false);
    }
    const openTab = (tab) => {
        window.open(tab, '_blank');
        //handleClickOpenWallet()
    }
    const btnStyles={
        gap:'0.5rem',
        '&:hover':{
            '&,div':{color:'#35F8FF',fill:'#35F8FF'},
            "svg>path":{
                fill:'#35F8FF'
            }
        },
        textDecoration:'none',
        fontWeight:'600',
        color:'#FEFFFF',
        textTransform:'uppercase',
        width:'100%',
        fontFamily:'BentonSansBBVA-Medium,sans-serif ',
        fontSize:{xs:'16px',sm:'22px'}
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    let selectGoogle = document.querySelector("#google_translate_element_mobile select");
    let array = document.querySelectorAll("#google_translate_element_mobile select option");

    const handleClose = async (val) => {
        selectGoogle = document.querySelector("#google_translate_element_mobile select");
        array = document.querySelectorAll("#google_translate_element_mobile select option");

        if(array && array.length > 0 && selectGoogle) {
            array.forEach((item,index) => {
                if(item.getAttribute("value") === val) {
                    selectGoogle.selectedIndex=index;
                    selectGoogle.dispatchEvent(new Event('change'));
                }
            });
        }
        setAnchorEl(null);
    };




    return (
       
        <Drawer 
            id='drawer-wallet'
            variant="persistent" 
            anchor="right" 
            open={openNav}
            className='drawer-nav'
            sx={{
                display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},
                "& .MuiDrawer-paper": {
                    backgroundColor:'#081A43',
                    border: "none",
                    top:{sm:'0px',md:'130px'},
                    right: "0px",
                    width: "450",
                    height: '100vh',
                    '@media (max-width:600px)': {
                        width: '200px',
                    }
                }
            }}
        >
            <OutsideClickHandler onOutsideClick={()=>setOpenNav(false)}>      
            <Box
                sx={{
                    width:"450",
                    color: '#fff',
                    '@media (max-width:600px)': {
                        width: 'auto',
                        overflowX: 'hidden'
                    }
                }}
            >
                <Box sx={{padding: '0.8rem'}}>
                    <IconButton onClick={()=>setOpenNav(false)} sx={{color:'#fff'}}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <CloseIcon />}
                    </IconButton>
                </Box>
                <Divider sx={{backgroundColor:'transparent',display:'none'}} />
                <List>
                    {tabs.map((tab, index) => (
                        <ListItem key={index} sx={{width:'100%'}}>
                            {tab.public ? 
                            <Box onClick={()=>openTab(tab.path)} display='flex' alignItems='center' 
                                sx={{
                                    gap:'0.5rem',
                                    width:'100%',
                                    textDecoration:'none',
                                    fontWeight:'600',
                                    color:'#FEFFFF',
                                    textTransform:'uppercase',
                                    fontFamily:'BentonSansBBVA-Medium,sans-serif ',
                                    '&:hover':{
                                        color:'#35F8FF'
                                    }}
                                }
                            >
                                {tab.icon && <tab.icon />}
                                <Box>{tab.name}</Box>
                            </Box>
                            :
                            <Box component={Link} to={tab.path} onClick={()=>setOpenNav(false)} display='flex' alignItems='center' sx={btnStyles}>
                                {tab.icon && <tab.icon />}
                                <Box>{tab.name}</Box>
                            </Box>}
                        </ListItem>
                    ))}
                    {data && data?.userAccount === "222dsds" &&    
                    <ListItem>
                        <Box component={Link} to='/create' onClick={()=>setOpenNav(false)} display='flex' alignItems='center' sx={btnStyles}>
                            <UploadFileIcon />
                            <Box>{t('top_bar.create')}</Box>
                        </Box>
                    </ListItem>}
                    {data && data.userAccount &&    
                    <ListItem>
                        <Box component={Link} to={`/profile?address=${data.userAccount}`} onClick={()=>setOpenNav(false)} display='flex' alignItems='center' sx={btnStyles}>
                            <PersonIcon />
                            <Box>{t('top_bar.profile')}</Box>
                        </Box>
                    </ListItem>}
                    {data && data.userAccount &&
                    <ListItem sx={{display:'none'}}>
                        <Box component={Link} to={`/notifications?address=${data.userAccount}`} onClick={()=>setOpenNav(false)} display='flex' alignItems='center' sx={btnStyles}>
                            <NotificationsNoneIcon
                                sx={{
                                    fontSize: '25px'
                                }}
                            />
                            <Box
                            sx={{
                                ml:'-1px',
                                color:'#fff', 
                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                            }}
                            >
                                Notifications
                            </Box>
                        </Box>
                    </ListItem> 
                    }

                    <Box id="google_translate_element_mobile" sx={{display:'none'}} />
                    <ListItem
                        onClick={handleClick}
                        id="basic-menu-mobile"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{
                            cursor: 'pointer',
                            display:'none'
                        }}
                    >
                        <LanguageIcon />
                        <Box
                            sx={{
                                ml:'8px',
                                color:'#fff', fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                            }}
                        >Language</Box>
                    </ListItem>
                    <Menu
                        id="basic-menu-mobile"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                            <MenuItem onClick={()=>{handleClose("zh-CN")}}>Chinese - Simplified</MenuItem>
                            <MenuItem onClick={()=>{handleClose("zh-TW")}}>Chinese - Traditional</MenuItem>
                            <MenuItem onClick={()=>{handleClose("en")}}>English</MenuItem>
                            <MenuItem onClick={()=>{handleClose("es")}}>Spanish</MenuItem>
                            <MenuItem onClick={()=>{handleClose("tr")}}>Turkish</MenuItem>
                    </Menu>
               
                    <ListItem onClick={handleClickOpenWallet}>
                        <Divider />
                        <Box display='flex' sx={btnStyles}>
                            <svg style={{padding:'1px'}} width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.6 3.20001C22.9255 3.20001 24 4.27453 24 5.60001V8.00001H0V5.60001C0 4.27453 1.07452 3.20001 2.4 3.20001H21.6Z" fill="#fff"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 9.60001V18.4C0 19.7255 1.07452 20.8 2.4 20.8L21.6 20.8C22.9255 20.8 24 19.7255 24 18.4V9.60001H0ZM3.2 16H12.8V14.4H3.2V16ZM20.8 16H16V14.4H20.8V16Z" fill="#fff"/>
                            </svg>
                            <Box
                                sx={{
                                    color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif '
                                }}
                            >Wallet</Box>
                        </Box>
                    </ListItem>
                </List>
               
            </Box>
            </OutsideClickHandler> 
        </Drawer>
    
    )
}

MobileBar.propTypes = {
    tabs: PropTypes.array,
}

export default MobileBar