import Web3 from 'web3'
import Contract from './PerseaSimpleCollection.json'
import axios from "axios";

export const metaTransfer = async(to,tokenId,address,from,provider) => {
    console.log(provider);
    //let web3 = new Web3.providers.HttpProvider(`${process.env.REACT_APP_RPC}`);
    let web3 = new Web3(provider);
    web3.setProvider(`${process.env.REACT_APP_RPC}`);
    const blockchain = process.env.REACT_APP_NETWORK_NAME;
    const domain = process.env.REACT_APP_DOMAIN
    let contract = new web3.eth.Contract(Contract.abi,web3.utils.toChecksumAddress(address))
    console.log('contract',contract)
    const nonce = await contract.methods.replayNonce(web3.utils.toChecksumAddress(to)).call()
    const args = [
        web3.utils.toChecksumAddress(from),
        tokenId,
        parseInt(nonce)
    ];
    console.log('args',args)
    const message = await contract.methods['metaTransferHash'](...args).call();
    console.log("message:",message);
    //let sig = await web3.eth.personal.sign(""+message,to);
    let sig = await provider.request({
        method: "personal_sign",
        params: [message, to]
    });
    //const accounts = await window.ethereum?.request({
    //  method: 'eth_requestAccounts',
    //});
    //const sig = await window.ethereum?.request({
    //  method: "personal_sign",
    //  params: [message, accounts[0]]
    //});
  
    let data = {
        message: message,
        args:args,
        signature:sig,
        project_key:address.toUpperCase(),
        owner:to.toUpperCase()
    }
    let url = `${process.env.REACT_APP_URL_API}/relay?domain=${domain}&blockchain=${blockchain}`;
    console.log('postData ::',data);
    return axios.post(url,data);
}

export const waitForReceipt = async(tx,web3) => {
  let receipt =  await web3.eth.getTransactionReceipt(tx);
  if (receipt !== null) {
    return receipt;
  } else {
    return await  waitForReceipt(tx,web3);
  }
}
