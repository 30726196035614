import React, { useContext } from 'react'
import { Context } from 'hooks/WalletContext';
import Wallet from '../Wallet';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { ButtonBBVA } from './styled';
import { useFetch } from 'hooks/useFetch';

const CustomNavLink = styled(NavLink)`
    text-decoration: none;
    border: none;
    padding: 0px 0px;
`;


const DesktopBar = ({tabs}) => {
    const { t  } = useTranslation("translate");
    const { data } = useContext(Context);
    const { openWallet, setOpenWallet } = useContext(DrawerMobileContext);
    const url = `${process.env.REACT_APP_URL_API}/collection?domain=${process.env.REACT_APP_DOMAIN}&key_name=NEWS&key_val=NEWS&limit=300&page=0&order=created`;
    const { data: collection, error, loading } = useFetch(url);
    const handleOpenWallet = () => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        setOpenWallet(!openWallet);
    };

    return (
        <React.Fragment>
            <Box 
                sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center',
                    gap:'20px',
                    mr:{xs:'5px',sm:'10px',md:'10px',lg:'30px'},
                    minHeight:'75px',
                    maxHeight:'75px'
                }}
            >
                {
                    tabs.map((tab, index) =>{
                        return (
                        <Box key={index}
                            component={CustomNavLink} 
                            to={tab.path}
                            sx={{
                                textDecoration:'none',
                                display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                                textTransform:'uppercase',
                                color:'#FEFFFF',
                                '&:hover':{
                                    color:'#35F8FF'
                                },
                                fontSize:'14px',
                                fontWeight:'600',
                                fontFamily:'BentonSansBBVA-Medium,sans-serif'
                            }}
                        >
                            {tab.name}
                        </Box>
                        )
                    })
                }
                {
                    collection != null && collection.length > 0 && collection[0] && !loading &&  !error &&
                    <ButtonBBVA type="button" LinkComponent={Link} to={`/collection?address=${collection[0].collection_key}`}>
                        Ver Colección
                    </ButtonBBVA>
                }
                {
                    loading &&
                    <CircularProgress size={25} sx={{color:'#FEFFFF'}}/>
                }
            </Box> 
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    minHeight:'75px',
                    maxHeight:'75px',
                    gap:'15px'
                }}
            >
                {(data && data.userAccount && data.user && data.user.role > 1)  &&
                <Box 
                    component={CustomNavLink} 
                    to='/create' 
                    sx={{
                        display: {xs:'none',sm: 'none', md: 'none',lg:'none',xl:'none'},
                        textDecoration:'none',
                        color:'#FEFFFF',
                        textTransform:'uppercase',
                        '&:hover':{
                            color:'#35F8FF'
                        },
                        fontSize:'14px',
                        fontWeight:'600',
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    {t('top_bar.create')}
                </Box>}
                {data && data.userAccount &&
                <Box
                    component={CustomNavLink}
                    to={`/profile?address=${data.userAccount}`}
                    sx={{
                        display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                        cursor:'pointer',
                        color:'#FEFFFF',
                        textTransform:'uppercase',
                        '&:hover':{
                            color:'#35F8FF',
                            "svg>path":{
                                fill:'#35F8FF'
                            }
                        },
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.579 2C8.66407 2 6.30103 4.36304 6.30103 7.278C6.30103 10.193 8.66407 12.556 11.579 12.556C14.494 12.556 16.857 10.193 16.857 7.278C16.857 4.36304 14.494 2 11.579 2ZM13.806 15.283C12.3212 15.1528 10.8278 15.1528 9.34301 15.283C8.5339 15.3393 7.72974 15.4524 6.93651 15.6216C6.37391 15.7371 5.73753 15.9252 5.17139 16.2152C4.61058 16.5024 4.06837 16.9158 3.7725 17.5075L3.7693 17.514C3.59072 17.8845 3.49866 18.2907 3.50001 18.702C3.49966 19.1146 3.59116 19.5221 3.7679 19.895L3.76996 19.8992C4.06975 20.5164 4.61347 20.9322 5.18071 21.2151C5.74833 21.4983 6.3845 21.6714 6.94549 21.7812C7.72632 21.9559 8.5192 22.0716 9.31739 22.1272C9.62872 22.1854 9.92446 22.1854 10.1792 22.1854H10.1835C11.3904 22.2497 12.6004 22.2309 13.8048 22.129C14.6149 22.0735 15.4197 21.9572 16.2124 21.7812C16.7686 21.6715 17.4049 21.4984 17.9727 21.2151C18.5392 20.9325 19.0852 20.516 19.3805 19.8957C19.7384 19.1431 19.7385 18.2691 19.3806 17.5164C19.0853 16.8962 18.5393 16.4796 17.9735 16.196C17.405 15.9111 16.7675 15.7356 16.2121 15.6215C15.4189 15.453 14.6149 15.3399 13.806 15.283Z" fill="white"/>
                    </svg>
                </Box>}
                <Box
                    onClick={handleOpenWallet}
                    sx={{
                        display: {xs:'none',sm: 'none', md: 'block',lg:'block',xl:'block'},
                        cursor:'pointer',
                        color:'#FEFFFF !important',
                        textTransform:'uppercase',
                        '&:hover':{
                            color:'#35F8FF',
                            "svg>path":{
                                fill:'#35F8FF'
                            }
                        },
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6 3.20001C22.9255 3.20001 24 4.27453 24 5.60001V8.00001H0V5.60001C0 4.27453 1.07452 3.20001 2.4 3.20001H21.6Z" fill="#fff"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 9.60001V18.4C0 19.7255 1.07452 20.8 2.4 20.8L21.6 20.8C22.9255 20.8 24 19.7255 24 18.4V9.60001H0ZM3.2 16H12.8V14.4H3.2V16ZM20.8 16H16V14.4H20.8V16Z" fill="#fff"/>
                    </svg>
                </Box>
            </Box>
            <Wallet />
        </React.Fragment>
    )
}

DesktopBar.propTypes = {
    tabs: PropTypes.array,
}

export default DesktopBar