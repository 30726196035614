import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const NotFoundText = ({text}) => {
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '150px'
                }}
            >
                <Typography variant="h3" component="h2" sx={{ color: '#fff' }}>
                {text}
                </Typography>
            </Box>
        </Container>
    );
};

NotFoundText.defaultProps = {
    text: 'Page not found - 404'
}

NotFoundText.propTypes = {
    text: PropTypes.string
};

export default NotFoundText;