import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import BackGroundDefault from 'assets/background/backgroundBannerDefault.svg'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import ModalCustom from 'components/ModalCustom';
import FormMyData from './components/FormMyData';
import Web3 from 'web3';
import AvalancheLogo from 'assets/logos/avalanche.png';
import IconBlockies from 'components/IconBlockies/IconBlockies';

const ActiveTag = styled(Box)`
    font-weight: ${props => props.active == "true" ? 'bold' : 'null'};
    opacity: ${props => props.active == "true" ? '1':'0.5'};
    font-family:'BentonSansBBVA-Medium',sans-serif;
    ${props => props.styles}
`

const Header = ({
    ownerMainBanner,
    formEditProfile,
    setFormEditProfile,
    address,
    nftOption,
    totalNFTs,
    getMyDataProfile,
    loadMyData,
    user,
    data,
    dataTemp,
    setDataTemp,
    openModalForm,
    setOpenModalForm
}) => {
    const {t} = useTranslation("translate");
    const [successEdit,setSuccessEdit] = React.useState(null);


    const handleCloseModal = () =>{
        if(!openModalForm && successEdit == null){
           alert(22);
        }
        setFormEditProfile(dataTemp)
        setSuccessEdit(null);
        setOpenModalForm(false)
    }


    return (
        <Box
            sx={{
                width:'100%',
                height:'100%',
                position:'relative',
                backgroundImage:`url(${ownerMainBanner != '' && ownerMainBanner != undefined ? ownerMainBanner:BackGroundDefault})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                objectFit: '100%',
                objectPosition: '100%',
                zIndex:'2',
                mt:'56px',
                mb:'56px',
                borderRadius:'10px 10px'
            }}
        >
            <Box
                sx={{
                    width:'100%',
                    height:'calc(100% + 66px)',
                    minHeight:'calc(170px + 66px)',
                    p:{xs:'16px 16px',sm:'16px 36px'},
                    boxSizing: 'border-box'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        gap:'2rem',
                        "@media screen and (max-width:360px)":{
                            display:'grid',
                            gridTemplateColumns:'repeat(1,1fr)'
                        }
                    }}>
                    <Box
                        sx={{
                            position:'relative',
                            width:{xs:'80px',sm:'120px'},
                            height:{xs:'80px',sm:'120px'},
                            "@media screen and (max-width:360px)":{
                                margin:'0 auto'
                            }
                        }}
                    >
                        <IconBlockies 
                            address={address} variant='circle' 
                            sx={{
                                width:{xs:'80px',sm:'120px'},
                                height:{xs:'80px',sm:'120px'},
                                border:'3px solid #FEFFFF'
                            }} 
                        />
                        <Box
                            sx={{
                                position:'absolute',
                                width:'100%',
                                right:'0px',
                                bottom:'0px'
                            }}
                        >
                            {
                                data && data.userAccount != 'undefined' && String(data.userAccount+'').toUpperCase() == String(address+'').toUpperCase() &&
                                <Box
                                    onClick={()=>{!loadMyData && getMyDataProfile();}}
                                    sx={{
                                        position:'absolute',
                                        bottom:'10px',
                                        right:'0px',
                                        width:'30px',
                                        height:'30px',
                                        borderRadius: '50% 50%',
                                        cursor:'pointer',
                                        background:'#081A43',
                                        color:'#FEFFFF',
                                        "&:hover":{
                                            color:'#35F8FF',
                                            border:'1px solid #35F8FF',
                                            background:'#081A43'
                                        }
                                    }}
                                >
                                    {
                                        loadMyData ?
                                            <CircularProgress size={20} sx={{color:'#fff',ml:'5px',mt:'5px'}}  />
                                        :
                                            <EditIcon sx={{ml:'5px',mt:'5px',fontSize:'20px'}}
                                        />
                                    }
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            width:{xs:'calc(100% - 80px)',sm:'calc(100% - 120px)'},
                            wordWrap: 'break-word'
                        }}
                    >
                        <Box
                            sx={{width:'calc(100% - 0px)',display:'grid',gridTemplateColumns:'repeat(1,1fr)',color:'#081A43'}}
                        >
                            <Box
                                sx={{
                                    width:'calc(100% - 10px)',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: {xs:'23px',sm:'32px',md:'32px',lg:'32px',xl:'32px'},
                                    lineHeight: '100%',
                                    margin:'0px 0px',
                                    fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                    wordWrap: 'break-word',
                                    "&":{
                                        fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                        wordWrap: 'break-word',
                                    }
                                }}
                            >
                                {
                                    formEditProfile && formEditProfile.username && formEditProfile.username != '' && !Web3.utils.isAddress(formEditProfile.username)  ?
                                    <React.Fragment>
                                        {formEditProfile.username}
                                    </React.Fragment>
                                    :
                                    (address).substring(0,5)+ '...' +(address).substring(38,54)
                                }
                            </Box>
                            <Box
                                sx={{
                                    mt:'6px',
                                    fontStyle: 'normal',
                                    fontWeight: '300',
                                    fontSize: '12px',
                                    lineHeight: '100%',
                                    display:'grid',
                                    letterSpacing: '-0.408px',
                                    gridTemplateColumns:'repeat(2,1fr)',
                                    maxWidth:'240px',
                                    "@media screen and (max-width:490px)":{
                                        gridTemplateColumns:'repeat(1,1fr)',
                                    },
                                    fontFamily:'BentonSansBBVA-Medium,sans-serif'
                                }}
                            >
                                <Box
                                    sx={{
                                        display:'flex',
                                        justifyContent:'flex-start',
                                        gap:'5px'
                                    }}
                                >
                                    <Avatar
                                        src={AvalancheLogo}
                                        sx={{width:'10px',height:'10px'}}
                                    />
                                    <a href={`${process.env.REACT_APP_SCAN}address/`+String(address+'').toLowerCase()} style={{textDecoration:'none',color:'#081A43'}} target="_blank" rel="nofollow noopener noreferrer">
                                        {(address).substring(0,5)+ '...' +(address).substring(38,54)}
                                    </a>
                                </Box>
                                <Box>

                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    mt:'16px',
                                    mb:{xs:'56px',md:'56px'},
                                    fontStyle: 'normal',
                                    fontWeight: '300',
                                    fontSize: '15px',
                                    lineHeight: '100%',
                                    fontFamily:'BentonSansBBVA-Medium,sans-serif'
                                }}
                            >
                                {
                                    formEditProfile && formEditProfile.description != '' ?
                                    formEditProfile.description
                                    :
                                    ''
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width:'100%',
                    height:'56px',
                    background:'rgba(0, 0, 0, 0.65)',
                    position:'absolute',
                    bottom:'0px',
                    left:'0px',
                    p:'16px 36px',
                    borderRadius:' 0px 0px 10px 10px'
                }}
            >
                <Box
                    sx={{
                        height:'100%',
                        width:'100%',
                        display:'flex',
                        alignItems:'center'
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',justifyContent: 'flex-start',gap:'20px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '20px',
                            lineHeight: '100%',
                            color:'#fff'
                        }}
                    >
                        <ActiveTag active={nftOption+''}  component='div' sx={{margin:'auto 25px', cursor:'pointer',fontFamily:'BenotonSansBBVA-Bold,sans-serif'}} >
                            {t("profile.tab_all")} ({totalNFTs})
                        </ActiveTag>
                    </Box>
                </Box>
            </Box>
            <ModalCustom
                isOpen={openModalForm}
                onCloseModal={handleCloseModal}
                title={t("profile.modal_edit.title")}
                height={'auto'}
            >
                <Box
                    sx={{
                        width:'100%',
                        height:{xs:'90vh',sm:'90vh',md:'100%',lg:'100%',xl:'auto'},
                        overflowY:'auto'
                    }}
                >
                    <FormMyData
                        formEditProfile={formEditProfile}
                        setFormEditProfile={setFormEditProfile}
                        setInitEditProfile={()=>{}}
                        isSetEditProfile={()=>{}}
                        user={user}
                        data={data}
                        dataTemp={dataTemp}
                        setDataTemp={setDataTemp}
                        setOpenModalForm={setOpenModalForm}
                        setSuccessEdit={setSuccessEdit}
                    />
                </Box>
            </ModalCustom>
        </Box>
    )
}

Header.propTypes = {
    ownerMainBanner: PropTypes.string,
    formEditProfile: PropTypes.object,
    setFormEditProfile: PropTypes.func,
    address: PropTypes.string,
    nftOption: PropTypes.bool,
    totalNFTs: PropTypes.number,
    getMyDataProfile: PropTypes.func,
    loadMyData: PropTypes.bool,
    user: PropTypes.object,
    data: PropTypes.object,
    dataTemp: PropTypes.object,
    setDataTemp: PropTypes.func,
    openModalForm: PropTypes.bool,
    setOpenModalForm: PropTypes.func
}


export default Header;
