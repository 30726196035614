import React from 'react';
import { Modal, Box, Container, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';


const ModalCustom = ({children, title, isOpen, onCloseModal, width})=>{
    return (
        <Modal
            open={isOpen}
            //onClose={()=>{setOpenModalTransfer(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backdropFilter: 'blur(1.5px)',
            }}
        >
            <Box 
                sx={{
                    width: width ? width : 1100,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#081A43',
                    border: '1px solid #000',
                    borderRadius:'8px',
                    boxShadow: 24,
                    p: 4,
                    overflow:'auto',
                    '@media (max-width:850px)':{
                        width: '100%',
                        height: '100%'
                    },
                    '@media (max-width:830px)':{
                        width: '100%',
                        height: '100%'
                    },
                    '@media (max-width:600px)':{
                        width: '100vw',
                        height: '100%'
                    }
                }}
            >
                <Container component='header'>

                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        position:'relative'
                    }}>
                    
                        <Box 
                            sx={{
                                position:'relative',
                                width: '100%',
                                height: '100%',
                            
                                '@media screen and (max-width: 360px)': {
                                    fontSize: '12px'
                                }
                            }}
                        > 
                            <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                                <h1 style={{textAlign:'center',color:'#fff'}}>{title}</h1>
                            </Box>
                        </Box>

                        <Box sx={{position:'absolute',top:'0',right:'0'}}>
                            <Button 
                                variant="contained" 
                                size="small"
                                onClick={()=>onCloseModal(!isOpen)}
                                sx={{
                                    width:'30px',
                                    borderRadius:"20px 20px 20px 20px",
                                    float:'right',
                                    border:'1px solid #fff',
                                    background:'#081A43',
                                    color:'#FEFFFF',
                                    "&:hover":{
                                        color:'#35F8FF',
                                        border:'1px solid #35F8FF',
                                        background:'#081A43'
                                    }
                                }}
                            >
                            <CloseIcon />
                            </Button>
                        </Box>


                    </Box>
                   
                </Container>
               
                {children}
            </Box>
        </Modal>
    )
}

ModalCustom.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    width: PropTypes.number,
}

export default ModalCustom;