import sanityClient from 'sanityClient';

const islang = localStorage.getItem("i18nextLng");

let lang = islang && islang.includes('es') ? 'es' : 'en';
console.log(lang);

export const presentationHome = ()=>{
    return sanityClient.fetch(`*[_type == "presentationHome"] {
        desktop,
        tablet,
        mobile
    }`).then((data) => data)
}

/**
 * It fetches the topBar data from the Sanity CMS and returns it as a promise.
 * @returns An array of objects.
 */
export const topBar = ()=>{
    return sanityClient.fetch(`*[_type == "topBar"] {
        menu,
    }`).then((data) => data)
}

/**
 * It fetches the footer data from the Sanity CMS and returns it as a promise.
 * @returns An array of objects.
 */
export const footer = ()=>{
    return sanityClient.fetch(`*[_type == "footer"] {
        es,
        socialIcon [] {
            social {
                asset->{
                    _id,
                    url
                } ,
            },
            link
        }
    }`).then((data) => data)
}

export const aboutSanity = ()=>{
    return sanityClient.fetch(`*[_type == "about"] {
        header,
        headerImage {
            asset->{
                _id,
                url
            } ,
        },
        blockBody [] {
            blockTitle,
            block
        },
        bodyImage {
            asset->{
                _id,
                url
            } ,
        },
        blockTitle,
        links [] {
            item,
            isLink,
            externalLink,
            link
        }
    }`).then((data) => data)
}

export const sanityFAQS = ()=>{
    return sanityClient.fetch(`*[_type == "faqs"] {
        title,
        subtitle,
        list []{
            question,
            answer
        }
    }`).then((data) => data)
}

export const sanityRoadmap = ()=>{
    return sanityClient.fetch(`*[_type == "roadmap"] {
        title,
        headerImage {
            asset->{
                _id,
                url
            } ,
        },
        block []{
            year,
            lapse,
            titleDescription,
            description
        }
    }`).then((data) => data)
}

export const sanityTermsAndConditions = ()=>{
    return sanityClient.fetch(`*[_type == "termsAndConditions"] {
        header,
        body [] {
            title,
            body 
        }
    }`).then((data) => data)
}

export const sanityPrivacyPolicy = ()=>{
    return sanityClient.fetch(`*[_type == "privacyPolicy"] {
        title,
        body [] {
            title,
            body 
        }
    }`).then((data) => data)
}

export const sanityBurnParagraph = ()=>{
    return sanityClient.fetch(`*[_type == "burnNFT"] {
        paragraph
    }`).then((data) => data)
}


export const layoutWhitetList = () => {
    return sanityClient.fetch(`*[_type == "layoutWhitetList"] {
        background {
            asset -> {
                _id,
                url
            }
        },
        backgroundMobile {
            asset -> {
                _id,
                url
            }
        },
        logo {
            asset -> {
                _id,
                url
            }
        },
        image {
            asset -> {
                _id,
                url
            }
        },
        title,
        description,
        startDate,
        disclamerForm,
        subtitle,
        subtitleDescription,
        codeTitle,
        code,
        disclamer
    }`)
        .then((data) => data)
        .catch((error) => error)
};



