import axios from "axios";

export const getUser = async(address) => {
    const blockchain = process.env.REACT_APP_NETWORK_NAME;
    const domain = process.env.REACT_APP_DOMAIN;

    return axios.get(process.env.REACT_APP_URL_API+`/user?domain=${domain}&owner=${address}&blockchain=${blockchain}&limit=1&page=0`,
        {
          headers: {
            'X-API-Key': process.env.REACT_APP_X_API_KEY
          }
        }
    )

}