import React from 'react'
import { Box, Container } from '@mui/material'
import PropTypes from 'prop-types'

/**
 * It's a function that takes in a prop called content and returns a Box component with a Container
 * component inside of it.
 * @param {String} content - A string that contains the description of the page.
 * @returns A function that returns a JSX element.
 */
const Description = ({content}) => {
    return (
        <Box 
            display='flex' 
            justifyContent='center' 
            alignItems='center' 
            sx={{marginTop:'2rem',marginBottom:'2.2rem',letterSpacing: '-0.02em'}}
        >
            <Container maxWidth='lg'>
                <Box
                    sx={{
                        width:{xs:'100%',sm:'100%',md:'80%',lg:'50%'},
                        fontSize:{xs:'25px',sm:'25px',md:'30px',lg:'30px'},
                        color:'#fff',
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    {content}
                </Box>
            </Container>
        </Box>
    )
}

Description.propTypes = {
    content: PropTypes.string,
}

export default Description