import detectEthereumProvider from '@metamask/detect-provider';
import web3Provider from 'services/web3'
import Web3 from 'web3';
import PropTypes from 'prop-types'

/**
 * It's a function that takes in 3 parameters, and returns a promise that resolves to a value. 
 * 
 * The 3 parameters are: 
 * 
 * 1. setMsg: a function that takes in a string and sets the state of the component to that string. 
 * 2. setData: a function that takes in a value and sets the state of the component to that value. 
 * 3. setIsLoading: a function that takes in a boolean and sets the state of the component to that
 * boolean. 
 * 
 * The promise resolves to a value that is the result of calling the web3Provider function. 
 * 
 * The web3Provider function is not shown in the code you posted, but I'm guessing it returns a promise
 * that resolves to a value. 
 * 
 * The metamask function is called in the componentDidMount function of the component. 
 * 
 * The
 * @param setMsg - is a function that sets the message to be displayed to the user
 * @param setData - this is a function that sets the data in the state
 * @param setIsLoading - a function that sets the loading state of the app
 */
const metamask = async(setMsg,setData,setIsLoading) => {
    const provider = await detectEthereumProvider().catch((err) =>{
       console.log("Error provider detectEthereumProvider()::",err);
    })

    await provider.request({ method: 'eth_requestAccounts' }).catch((error) => { 
        console.log("debug  provider.request err",error);
        if(error && error.code === -32002){
            setMsg("Please open your Wallet in your browser")
        }
    })
//update metamask
    if(provider){
        return new Promise((resolve, reject) => {
            try{
                localStorage.setItem('isMetamask', true)
                localStorage.setItem('wallet', true)
                web3Provider(provider).then(res => {
                    resolve(res);
                })
                
                provider.on('accountsChanged', async() => {
                    setIsLoading(true);
                    localStorage.clear();
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            window.location.reload();
                        }else{
                            setData(x);
                        }
                        setIsLoading(false)
                    }).catch(()=>{
                        setIsLoading(false)
                    })
                    )
                });
                provider.removeListener('accountsChanged', () => {
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            localStorage.clear()
                        }
                        window.location.reload()
                    }))
                });
                provider.on('chainChanged', () => {
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            localStorage.clear()
                        }
                        window.location.reload()
                    }))
                });
                provider.removeListener('chainChanged', () => {
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            localStorage.clear()
                        }
                        window.location.reload()
                    }))
                });
            }catch(err){
                localStorage.clear()
                setMsg("please open your metamask in your browser")
                reject(err)
            }
        })
    }else{
        alert('Metamask is not installed')
    }

}

/**
 * It's a function that takes a network as a parameter and then checks if the network is equal to 0, if
 * it is, it returns. If it's not, it checks if the network is equal to the
 * process.env.REACT_APP_NETWORK, if it is, it creates a provider object and then calls the
 * wallet_addEthereumChain method with the provider object as a parameter. If it's not, it calls the
 * wallet_switchEthereumChain method with the network as a parameter.
 * @param network - The network ID of the chain you want to switch to.
 * @returns {
 *   "jsonrpc": "2.0",
 *   "id": 1,
 *   "result": {
 *     "chainId": "0x1",
 *     "chainName": "Mainnet",
 *     "nativeCurrency": {
 *       "name": "ethereum",
 *       "symbol": "ethereum",
 */
const switchEthereumChain = async(network) => {
    if (window?.ethereum) {
        try {
          if (network === 0) return;
            console.log('network ::',network)
            if (process.env.REACT_APP_NETWORK == network) {
                console.log('hex ::', Web3.utils.toHex(process.env.REACT_APP_NETWORK))
                let provider = {
                        chainId: Web3.utils.toHex(process.env.REACT_APP_NETWORK),
                        chainName: process.env.REACT_APP_NETWORK_NAME,
                        nativeCurrency: {
                        name: 'ethereum',
                        symbol: 'ethereum',
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_RPC],
                    blockExplorerUrls:[process.env.REACT_APP_SCAN]
                }
                await window?.ethereum.request({method: "wallet_addEthereumChain", params: [provider]})
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            } else {
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            }
        } catch (error) {
            console.log('Error ::', error);
            //alert('Error to change network')
        }
    }
}

metamask.propTypes ={
    setMsg: PropTypes.func
}
export { switchEthereumChain }
export default metamask