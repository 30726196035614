import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CardNFT from 'components/Cards/CardNFT';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
//import { isMinted } from 'services/ERC721/isMinted';

const NFTs = ({content,loadingNFTs,limit,setSliceNFT,listCards,finishAddCards}) => {
    const {t} = useTranslation("translate");
    let contentCards = 'sectionNFts';
    let allCards = document.querySelectorAll("#sectionNFts .nftCard");
    let ultimo = null;
   
   
    React.useEffect(()=>{   
        if(!loadingNFTs && content && limit <= content.length){
            let observerNFtExplore = new IntersectionObserver((cards)=>{
                cards.forEach((card)=>{
                    if(card.isIntersecting){
                        observerNFtExplore.unobserve(ultimo)
                        setSliceNFT(limit + listCards)
                    }
                })
            },
            {
                rootMargin:'0px 0px 270px 0px',
                threshold:1.0
            })

            allCards = document.querySelectorAll("#sectionNFts .nftCard");
            if(allCards && allCards.length > 0){
                ultimo = allCards[allCards.length-1];
                observerNFtExplore.observe(allCards[allCards.length-1])
            }
        }
    }, [allCards,loadingNFTs,limit,content]);

    if(finishAddCards && content == null || finishAddCards && (content && content.length === 0)) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',mt:'20px',color:'#fff'}}
            >
                <h2>
                    {t("profile.any_results")}
                </h2>
            </Box>
     
        )
    }
    
    return (
        <React.Fragment>
            {
                finishAddCards && !loadingNFTs &&
                <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
                    <Grid 
                        container 
                        columns={{xs:12,sm:12, md:12, lg:12, xl:12}}
                        rowSpacing={4} 
                        spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                        id={contentCards}
                    >
                        {
                            content?.slice(0,limit).map((item, index)=>{
                            return (<Grid 
                                key={index} 
                                item 
                                xs={12}
                                sm={6} 
                                md={4} 
                                lg={4} 
                                xl={4}
                                sx={{
                                    width:'100%',
                                    minHeight:'380px',
                                    maxHeight:'400px'
                                }}
                                className="nftCard"
                            >
                                <Link 
                                    to={`/nft?address=${item.collection_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                                    style={{
                                        textDecoration: 'none',
                                        minHeight:'400px'
                                    }}
                                >
                                    <CardNFT item={item}/>
                                </Link>
                            </Grid>)
                            })
                        }
                    </Grid>
                </Container>
            }
        </React.Fragment>
        
    )
}

NFTs.defaultProps = {
    content: [],
    loadingNFTs: false,
    limit: 10,
    setSliceNFT: ()=>{},
    listCards: 100
}

NFTs.propTypes = {
    content: PropTypes.array,
    loadingNFTs: PropTypes.bool,
    limit: PropTypes.number,
    setSliceNFT: PropTypes.func,
    listCards: PropTypes.number,
    finishAddCards: PropTypes.bool
}


export default NFTs;