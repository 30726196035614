import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonStyled = ({text, maxWidth, width, isDisabled=false, onClick, icon, type = 'submit'})=>{

    return (
        <Button
            variant="contained"
            disabled={isDisabled}
            endIcon={icon}
            onClick={onClick}
            type={type}
            sx={{
                marginLeft:'5px',
                width: width ? width : '120px',
                maxWidth: maxWidth ? maxWidth :  '100%',
                transition: 'all 0.2s ease-in-out',
                boxShadow:'none',
                border:'1px solid #fff',
                background:'#081A43',
                color:'#FEFFFF',
                "&:hover":{
                    color:'#35F8FF',
                    border:'1px solid #35F8FF',
                    background:'#081A43'
                },
                '@media screen and (max-width: 650px)': {
                    width:'100%',
                    marginTop:'5px',
                    marginBottom:'5px',
                    marginLeft:'0px'
                },
                fontFamily:'BentonSansBBVA-Medium,sans-serif'                
            }} 
        >
            <span>
                {text}
            </span>
        </Button>
    )
}

ButtonStyled.propTypes = {
    text: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    width: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    type: PropTypes.string,
    maxWidth: PropTypes.string,
}

export default ButtonStyled;