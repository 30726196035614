import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card,CardMedia,Grid} from '@mui/material';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const CustomNavLink = styled(NavLink)`
    text-decoration: none;
    border: none;
    padding: 0px 0px;
`;
const collectionBackground = {
    width:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'100%'},
    height:{xs:'300px',sm:'300px',md:'350px',lg:'480px',xl:'480px'},
    display:'grid',
    gridTemplateColumns:{xs:'repeat(2,1fr)',sm:'repeat(2,1fr)',md:'repeat(2,1fr)',lg:'repeat(2,1fr)',xl:'repeat(2,1fr)'},
    //padding:'0px 20px'
    //p: {xs:'8px',sm:'10px',md:'20px',lg:'20px',xl:'20px'}
}

const cardCollection = {
    maxWidth:{xs:'100%',sm:'100%',md:'100%',lg:'100%',xl:'500px'},
    height:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    flexDirection:{xs:'column',sm:'column',md:'column',lg:'column',xl:'column'}
}

const CardCollection =  ({item}) => {
    const { t } = useTranslation("translate");
    return (
        <Grid item  xs={12} sm={11.7} md={11.7} lg={11.7} xl={11.7}
            sx={{
                width:'100%',
                background:'#081A43',
                borderRadius:'20px'
            }}
        >
            <Box sx={collectionBackground}>
                <Box sx={cardCollection}>
                    <Box
                        sx={{
                            display:'flex',
                            width:{xs:'calc(90% - 3px)',sm:'calc(90% - 3px)',md:'calc(85% - 3px)',lg:'calc(85% - 3px)',xl:'calc(85% - 3px)'},
                            m:'0 auto',
                            justifyContent:'flex-start',
                            fontSize:{xs:'18px',sm:'16px',md:'18px',lg:'24px',xl:'24px'},
                            color:'#fff',
                            fontWeight:'bold'
                        }}
                    >
                        {
                            String(item.name).length > 33 ?
                            <Tooltip 
                                title={item.name}
                                placement="top"
                            >   
                                <Box>
                                    {String(item.name).substring(0,30) + '...'}
                                </Box>
                            </Tooltip>
                            :
                            item.name
                        }
                    </Box>
                    <Box
                        sx={{
                            width:{xs:'calc(90% - 3px)',sm:'calc(90% - 3px)',md:'calc(85% - 3px)',lg:'calc(85% - 3px)',xl:'calc(85% - 3px)'},
                            m:'0 auto',
                            textAlign:'left',
                            height:'auto',
                            wordWrap: 'break-word',
                            color:'#fff',
                            fontSize:{xs:'16px',sm:'14px',md:'16px',lg:'17px',xl:'17px'}
                        }}
                    >
                        {
                            String(item.description).length > 80 ?
                            <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: String(item.description).substring(0,140) + '...' }}></Box>} placement="top">   
                                {window.screen.width > 768 ? 
                                    <Box dangerouslySetInnerHTML={{ __html: String(item.description).substring(0,119) + '...' }}></Box>
                                    :
                                    <Box dangerouslySetInnerHTML={{ __html: String(item.description).substring(0,80) + '...' }}></Box>
                                }
                            </Tooltip>
                            :
                            <Box dangerouslySetInnerHTML={{ __html: item.description}}></Box>
                        }
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            width:{xs:'calc(90% - 3px)',sm:'calc(90% - 3px)',md:'calc(85% - 3px)',lg:'calc(85% - 3px)',xl:'calc(85% - 3px)'},
                            m:'0 auto',
                            justifyContent:'flex-start',
                            mt:'40px'
                        }}
                    >
                        <Button 
                            component={CustomNavLink}
                            sx={{
                                fontFamily:'BentonSansBBVA-Medium, sans-serif',
                                color:'#000',
                                background: '#028484',
                                "&:hover":{
                                    background:'#02a5a5'
                                },
                                fontSize:{xs:'16px',sm:'14px',md:'16px',lg:'17px',xl:'17px'},
                                "@media screen and (max-width:375px)":{
                                    fontSize:'14px'
                                }
                            }}
                            to={`/collection-buy?address=${item.collection_key}`}
                        >
                            {t("collections.go_to_collection")}
                        </Button>
                    </Box>
                </Box>
                <Box sx={cardCollection}>
                    <Box>
                        <Card
                            sx={{
                                width:{xs:'calc(90% - 3px)',sm:'calc(90% - 3px)',md:'calc(85% - 3px)',lg:'calc(85% - 3px)',xl:'calc(85% - 3px)'}
                            }}
                        > 
                            <CardMedia
                                component={item.is_video ? "video" : "video"}
                                src={item.thumb_url}
                                alt={item.name}
                                autoPlay={item.is_video}
                                sx={{
                                    borderRadius: '8px',
                                    objectFit: 'cover',
                                    width: '100%',
                                    aspectRatio: '1 / 1'
                                }}
                            />
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

CardCollection.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardCollection;