import React from 'react'
import { Box, CardMedia } from '@mui/material';
import PropTypes from 'prop-types';
import { RiShareBoxFill } from 'react-icons/ri';
// import banner from 'assets/logos/banner_bbva.jpg';
// import nftBBVA from 'assets/video/nftVideo.mp4';

/**
 * It's a function that returns a Box component
 * @param {String} background - A string that contains the url of the background image.
 * @param {Object} content - An object that contains the information of the collection.
 * @param {Boolean} isVideo - A boolean that indicates if the collection is a video.
 * @param {String} randomThumb - A string that contains the url of the random thumbnail.
 * @param {Object} children - A Node that contains the children of the component.
 * @returns A Box component with a background image, a video, and a button.
 */
const Banner =  ({background,isVideo, randomThumb,content,children}) => { 


        console.log('Banner content:', content)
        console.log('Banner background:', background)
        console.log('Banner isVideo:', isVideo)
        console.log('Banner randomThumb:', randomThumb)
        


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            sx={{
                mt: 2,
                gap:'10px',
                backgroundImage: `url(${background})`,
                backgroundRepeat:'no-repeat',
                backgroundSize: 'calc(100% - 0px) calc(100% - 0px)',
                minWidth:'100%',
                width: '100%',
                height: { xs: '160px', sm: '290px', md: '340px', lg: '480px', xl: '560px' },
                "@media screen and (max-width:780px)":{
                    height: '220px', 
                },
                "@media screen and (max-width:600px)":{
                    height: '160px', 
                },
                "@media screen and (max-width:350px)":{
                    height: '120px', 
                }
            }}
        >
            <Box 
                sx={{
                    width:'100%',
                    height:'100%',
                    display:'none',
                    justifyContent:{xs:'center',sm:'space-between',md:'space-between',lg:'space-between',xl:'space-between'},
                    flexDirection:{xs:'column-reverse',sm:'row',md:'row',lg:'row',xl:'row'}
                }}
            >
                <Box>
                    {children}
                </Box>
                <Box display="none">
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center'
                        }}
                    >
                        <Box
                            sx={{
                                boxSizing:'border-box',
                                padding: '10px',
                                borderRadius:'8px',
                                backgroundColor:'transparent',
                                '@media screen and (max-width: 768px)':{
                                    marginTop:'0px',
                                },
                               
                            }}
                        >
                            <CardMedia  
                                component={ isVideo ? 'video' : 'img'}
                                alt='collection'
                                src={randomThumb}
                                autoPlay
                                loop
                                muted
                                sx={{
                                    width:'120px',
                                    height:'120px',
                                    borderRadius:'8px',
                                    border:'7px solid #081A43'
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center'
                        }}
                    >
                        <Box
                            display='inline-flex'
                            alignItems='center'
                            justifyContent='space-between'
                            onClick={()=>window.open(`${process.env.REACT_APP_SCAN}address/${String(content.collection_key).toLowerCase()}`, '_blank')}
                            sx={{
                                cursor: 'pointer',
                                marginTop:'0.5rem',
                                gap:'0.5rem',
                                backgroundColor:'transparent',
                                boxSizing:'border-box',
                                padding:'0.5rem 1rem',
                                borderRadius: '999px',
                                textAlign:'center',
                                fontSize:'18px',
                                letterSpacing:'1px',
                                fontFamily:'BenotonSansBBVA-Bold,sans-serif',
                                color:'#081A43',
                                "&.MuiButton-endIcon,svg":{
                                    color:'#081A43',
                                },
                                "&:hover":{
                                    color:'#35F8FF',
                                    "&.MuiButton-endIcon,svg":{
                                        color:'#35F8FF'
                                    }
                                },
                            }} 
                        >
                            <span>
                                {(content.collection_key).substring(0,5)+ '...' +(content.collection_key).substring(38,54)}
                            </span>
                            <span><RiShareBoxFill /></span>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

Banner.propTypes = {
    content: PropTypes.object.isRequired,
    background: PropTypes.string.isRequired,
    isVideo: PropTypes.bool.isRequired,
    randomThumb: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default Banner