import React, { useContext }from 'react';
import { BrowserRouter, Route , Routes as RoutesMain, Navigate } from "react-router-dom";
import Main from 'layouts/Main';
import CollectionBuy from 'views/CollectionBuy';
import CreateCollection  from 'views/CreateCollection';
import SelectCollection from 'views/SelectCollection';
import Collection from 'views/Collection';
import EditCollection from 'views/EditCollection';
import NFT from 'views/NFT';
import Profile from 'views/Profile';
import PreMintMassive from 'views/PreMintMassive';
import Create from 'views/Create';
import { Context } from 'hooks/WalletContext';
import NotFoundText from 'components/NotFound/NotFoundText';
import Collections from 'views/Collections';

const Routes = () => {
    const { data } = useContext(Context);
    return (
            <BrowserRouter>
                <Main>
                    <RoutesMain>
                        <Route path='/' element={<Navigate to="/collection-get" replace />} />
                        <Route path='/collection-get' element={<Collections/>} />
                        <Route path='/collection' element={<Collection/>} />
                        <Route path='/collection-buy' element={<CollectionBuy />} />
                        <Route path='/nft' element={<NFT/>} />
                        <Route path='/profile' element={<Profile/>} />
                        { data && data.user && data.user.role > 1 && data && data.userAccount ?
                            <Route path='/create/*' element={<Create/>} >
                                <Route path='create-collection' element={<CreateCollection/>} />
                                <Route path='edit-collection' element={<EditCollection/>} />
                                <Route path='select-collection' element={<SelectCollection/>} />
                                <Route path='massive-pre-mint-nft' element={<PreMintMassive/>} />
                            </Route>
                            :
                            <Route path='*' element={<Navigate to="/collection-get" replace />} />
                        }
                        <Route path='*' element={<NotFoundText/>} />
                    </RoutesMain>
                </Main>
            </BrowserRouter>
  );
};

export default Routes;
