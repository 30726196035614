import React from 'react';
import TopBar from './Topbar';
import { Box} from '@mui/material';
import { MainContainer ,TopBarContent, BodyContent} from './styled';
import PropTypes from 'prop-types';
import Footer from './Footer';
import './style.css';

const Main = ({ children }) => {
    return (
        <MainContainer>
            <TopBarContent>
                <TopBar />
            </TopBarContent>
            <BodyContent className="custom-scroll">
                <Box sx={{width:'100%',height:'64px'}}/>
                <Box sx={{minHeight:'calc(100vh - 195px)'}}>
                {children}
                </Box>
                <Footer />
                <Box sx={{width:'100%',height:'10px'}}/>
             </BodyContent>          
        </MainContainer>
    )
}

Main.propTypes = {
    children: PropTypes.node,
}

export default Main