import React, { useContext, useEffect } from 'react'
import { Box, Button } from '@mui/material';
import { useLocation ,Link} from 'react-router-dom'
import { useFetch } from 'hooks/useFetch';
import { Context } from 'hooks/WalletContext';
import { CollectionCard } from './components';
import LoaderCollection from 'components/LoaderCollection';
import ErrorMessage from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';

const SelectCollection = () => {
    const { t } = useTranslation("translate");
    const {data}=useContext(Context)
    const {search} = useLocation()
    const query = new URLSearchParams(search)
    const address = query.get('address')
    const project = `${process.env.REACT_APP_URL_API}/collection?domain=${process.env.REACT_APP_DOMAIN}&page=0&limit=30&order=created`
    const {data:projectData, loading, error} = useFetch(project)


    useEffect(()=>{
        if(!address || address && !data.userAccount || address == 'undefined'){
            window.location.href = '/'
        }
    },[])

    useEffect(() => {
        let customScroll = document.querySelector(".custom-scroll")
        if(customScroll){
            customScroll.scrollTo(0,0)
        }
        window.scrollTo(0,0)
    },[]);


    if(projectData == "" || projectData && projectData.length === 0){
        return (
            <>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    marginTop:'2rem',
                    color:'#fff',
                    fontFamily:'BentonSansBBVA-Medium,sans-serif'
                }}
            >
                <h1>{t("select_collection_view.collection_empty_text")}</h1>
            </Box>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                    marginTop:'2rem',
                    color:'#fff'
                }}
            >
                <Button 
                    component={Link} 
                    to='/create/create-collection'
                    sx={{
                        background:'#081A43',
                        color:'#FEFFFF',
                        padding:'1rem',
                        borderRadius:'6px',
                        "&:hover":{
                            color:'#35F8FF',
                            border:'1px solid #35F8FF',
                            background:'#081A43'
                        },
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    {t("select_collection_view.create_collection_btn")}
                </Button>
            </Box>
            </>
        )
    }
    return (
        <>
            <Box component='section' sx={{marginBottom:'2rem'}}>
                <Box 
                    component='h1' 
                    sx={{
                        fontSize:'30px',
                        textAlign:'center',
                        color:'#fff'
                    }}
                >
                    {t('select_collection_view.select_collection')}
                </Box>
                {loading && <LoaderCollection />}
                {error && <ErrorMessage error={error} />}
                {projectData && <CollectionCard content={projectData} showBtnAll={false} limit={50}/>}
            </Box>
        </>
       
    )
}

export default SelectCollection
