import React, { lazy, useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import { useFetch } from 'hooks/useFetch';
import { useLocation } from 'react-router';
import ErrorMessage from 'components/ErrorMessage';
import LoaderCircle from 'components/LoaderCircle';
import { useTranslation } from 'react-i18next'; 
import { CircularProgress, Typography } from '@mui/material';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import NFTs from 'views/Collections/components/NFTs';
import CardNFT from './CardNFT';
import { Link } from 'react-router-dom';
import { ClaimButtonContainer } from './styled';
const NavInfo = lazy(() => import('./components/NavInfo'));
import { StatusTx } from 'hooks/StatusTxContext';
import { Context } from 'hooks/WalletContext';
import ButtonStyled from 'components/ButtonStyled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { metaTransfer } from 'services/ERC721/metaTransfer';
//import { transferWithSign } from 'services/collection/transferWithSign';


const coloText = '#2DCCCD';
const colorBtn = '#FFF';

/**
 * It's a function that returns the name of the nft
 * @param {object} nft The object to get the name nft
 * @returns A component that is box with the name
 */
const Name = ({nft}) =>{
    //const { t } = useTranslation("translate"); 
    return (
        <Box sx={{ fontSize: '3em',width:'100%',padding:{xs:'0px 0px',sm:'0px 0px',md:'0px 22px'}}}>
            <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                {nft && nft.metadata && nft.metadata.json_data.name ?
                    <p style={{marginTop:'10px'}}>{nft && nft.metadata && nft.metadata.json_data.name}</p>
                    :  nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute, index) => (
                        attribute.trait_type == 'Name' && <p key={index} style={{marginTop:'10px'}}>{attribute.value}</p>
                    ))
                }
            </Typography>
        </Box>
    )
}

Name.propTypes = {
    nft: PropTypes.object
}

/**
 * It's a function that returns the description of the nft
 * @param {object} nft The object to get the description nft
 * @returns A component that is box with the description
 */
const Description = ({nft}) =>{
    const [collapseDescription, setCollapseDescription]=useState(false);
    return (
        <Box sx={{width:'100%',padding:{xs:'0px 0px',sm:'0px 0px',md:'0px 22px'}}}>
            {  nft && nft.reveal.confirmed && nft.metadata && nft.metadata.json_data.description ?
                <Typography gutterBottom variant="h6" component="h2" sx={{color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif'}} >
                    {collapseDescription ? 
                        <p style={{marginTop:'10px'}}>{nft.metadata.json_data.description}</p> : 
                        <p style={{marginTop:'10px'}}>{nft.metadata.json_data.description.substring(0,500)}</p>
                    }
                    { nft.metadata.json_data.description.length > 500 ?
                    <React.Fragment>
                        {
                        !collapseDescription ? 
                        <TiArrowSortedDown onClick={()=>setCollapseDescription(!collapseDescription)} style={{cursor:'pointer'}} size={20} /> 
                        :
                        <TiArrowSortedUp onClick={()=>setCollapseDescription(!collapseDescription)} style={{cursor:'pointer'}} size={20} />
                        }
                    </React.Fragment> 
                    : null }
                </Typography>
                :nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute, index) => (
                    attribute.trait_type == 'Description' && <p key={index} style={{marginTop:'10px'}}>  {attribute.value}</p>
                ))
            }
        </Box>
    )
}

Description.propTypes = {
    nft: PropTypes.object
}

/**
 * It's a function that returns the metadata of the nft
 * @param {object} nft The object to get the metadata nft
 * @returns A component that is grid item2
 */
const Item2 = ({nft}) => {
   
    const { t } = useTranslation("translate"); 
    const { data: wallet } =useContext(Context);
    const { sign } = useContext(StatusTx);
    const [statusTx, setStatusTx] = useState(false);
    const [errorTx, setErrorTx] = useState(null);
    const [successTx, setSuccessTx] = useState(null);
    const [hideElement, setHideElement] = useState(null);
    const [msgTx, setMsgTx] = useState(null);

    let temp = 22;
    if(temp == -22){
        console.log('new nft',sign)
    }
   
    const handleClaim = async () => {
        setStatusTx(true);
        setMsgTx(null);
        setHideElement(true);
        setErrorTx(null);
        //const message = 'Claim NFT';
        console.log('project key', nft.project_key)
        console.log('contract address', nft.token_id)
        try {
            metaTransfer(wallet.userAccount, nft.token_id, nft.project_key,nft.project.user.main_key,wallet.provider).then((tx) => {
                console.log('transaction', tx)
                setStatusTx(false);
                setErrorTx(null);
                setSuccessTx(true);
                setMsgTx(tx);
                setHideElement(true);
            }).catch(error => {
                //console.log('hay que manejar los errores, mostrar los errores que el servidor manda',error)
                setStatusTx(false);
                setHideElement(false);
                setMsgTx(null);
                setSuccessTx(false);
                setErrorTx(error);
            })
        } catch (error) {
            console.log(error);
            setStatusTx(false);
            setHideElement(false);
            setMsgTx(null);
            setSuccessTx(false);
            setErrorTx("Please contact support with the administrator");
        }
    }; 
    return (
        <Box sx={{width:'100%',maxWidth:'calc(100% - 0px)',padding:'0px 20px'}}>
            <Name nft={nft}/>
            <Description nft={nft}/>
           
            {nft && <NavInfo nft={nft}  />}

            {statusTx && !errorTx && !successTx && (
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ mt: 2 }}>
                    <CircularProgress sx={{ color: '#FEFFFF' }} />
                </Box>
            )}
            {!statusTx && errorTx && !successTx && (
                <ErrorMessage error={errorTx} />
            )}
            {!statusTx && !errorTx && successTx && msgTx && (
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ mt: 2 }}>
                    <CheckCircleIcon fontSize="large" sx={{ color: colorBtn }} />
                    <Box
                        component={Link}
                        to={`/profile?address=${String(wallet?.userAccount).toUpperCase()}`}
                        sx={{
                            textDecoration:'none',
                            color:coloText,
                            "&:hover":{
                                color:'#FEFFFF'
                            }
                        }}
                    >
                        <small>
                            {t("collection_buy_view.go_to_profile")}
                        </small>
                    </Box>
                </Box>
            )}
            {wallet && 
                wallet?.userAccount && 
                !statusTx && 
                !hideElement && (String(nft?.user?.main_key.toUpperCase()) == String(nft?.project?.user?.main_key.toUpperCase())) && (
                <Box display="flex" alignItems="center"
                    sx={{minHeight:'100px'}} 
                >
                    <ClaimButtonContainer>
                        <ButtonStyled isDisabled={hideElement}  text={t("nft-screen.claim_btn")} onClick={handleClaim} />
                    </ClaimButtonContainer>
                </Box>
            )}

            <Container maxWidth="md">
                <React.Fragment>
                        {nft && nft.metadata && nft.metadata.json_data.attributes.length > 0 &&
                        <Typography 
                            gutterBottom variant="h5" 
                            component="h2" 
                            sx={{
                                fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                marginTop:'25px',
                                '@media screen and (max-width: 1280px)': {
                                    marginTop:'20px',
                                }
                            }}
                        >
                            {t("nft-screen.attributes")}
                        </Typography>
                    }
                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 2, md: 4, lg: 9}}>
                        {nft && nft.metadata && (nft.metadata.json_data.attributes).map((attribute, index) => (
                            attribute.trait_type != 'Description' &&  attribute.trait_type != 'Name' && attribute.trait_type != 'Number' &&
                            <Grid key={index} item xs={1} sm={1} md={2} lg={3}>
                                <Card
                                    sx={{
                                        background:'transparent',
                                        border:'1px solid rgba(3, 15, 42,.5)',
                                        borderRadius:'8px',
                                        boxSizing: 'border-box',
                                        padding: '4px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minHeight: '70px',
                                        boxShadow:'0px 0px 10px rgba(3, 15, 42,.5)'
                                    }}
                                >
                                <ListItemText 
                                        primaryTypographyProps={{style: {color:'#fff'}}}
                                        secondaryTypographyProps={{style: {color:'#FEFFFF'}}}
                                        primary={attribute.trait_type ? (attribute.trait_type) : ''} 
                                        secondary={attribute.trait_type ? (attribute.value) : ''}
                                        sx={{textAlign:'center',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            </Container>
        </Box>
    )
}

Item2.propTypes = {
    nft: PropTypes.object
}

/**
 * It's a function that returns all the data of the nft
 * @returns A component NFT 
 */
const NFT = () => {
    const { t } = useTranslation("translate"); 
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const address = query.get("address");
    const tokenId = query.get("token_id");
    const [nft,setNFT] = useState(null);
    const [url,setUrl]= useState(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}&limit=1&order=created&page=0`);
    const [countError1,setCountError1] = useState(0);
    let {data, error, loading} = useFetch(url,countError1);
    
    const limitNFTRef = React.useRef(6);
    //const pageNftRef = React.useRef(0);
    const urlNft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&order=created&page=0`
    //const urlNft = url
    let { data: nftData, error:errorNFt, loading:loadingNFT} = useFetch(urlNft);

    let timeOut1 = null ;
    useEffect(() => {
        timeOut1 = null;
        clearTimeout(timeOut1)
        setUrl(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}&limit=1&order=created&page=0`)
        if(!loading && error) {
            if(countError1 < 3){
                timeOut1 = setTimeout(() =>{
                    setCountError1(countError1+1)
                    clearTimeout(timeOut1)
                    return null;
                },5000);
               
            }else{
                return null;
            }
        }
    },[error,countError1,loading]);

    useEffect(() => {
        if(!loading && data && data.length > 0){
            setNFT(data[0])
        }
        console.log(nft)
    },[data,loading]);
    useEffect(() => {
        setCountError1(0)
        setUrl(`${process.env.REACT_APP_URL_API}/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}&limit=1&order=created&page=0`)
    },[address,tokenId]);

    useEffect(() => {
        let customScroll = document.querySelector(".custom-scroll")
        if(customScroll){
            customScroll.scrollTo(0,0)
        }
        window.scrollTo(0,0)
    },[]);

    if(loading){
        return (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                <LoaderCircle text={t('message_loader.nft_screen.loading')} />
            </Box>
        )
    }

    return (
        <Container maxWidth="xl" sx={{ color: '#fff', mt: 5 }}>
            {error && (countError1 == 3) && !loading && 
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                <ErrorMessage error={error} />
            </Box>
            }
            {(loading || countError1 < 3) && !data &&
             <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                <LoaderCircle text={t('message_loader.nft_screen.loading')} />
             </Box>}
            {
                !loading && data && data.length > 0 && nftData && nftData != null &&
                <Box
                    sx={{
                        width:'100%',
                        display: 'grid',
                        gridTemplateColumns:{xs:'1fr',sm:'1fr',md:'40% 1fr'}
                    }}
                >
                    <Box sx={{
                        width:'100%',
                        //boxShadow:'inset 0 0 0 10px rgba(3, 15, 42,.5)',
                        borderRadius:'16px',
                    }}>
                        <CardNFT item={data[0]} />
                    </Box>
                    <Item2 nft={data[0]}/>
                </Box>
            }
            {
                !errorNFt && nftData && !loadingNFT && !error && !loading && 
                <React.Fragment>
                    <Divider sx={{ width: '100%', color: '#fff', mt: 5 }} />
                    <Box display="flex" justifyContent="space-between" sx={{ mt: 2 ,fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                        <Box>{t("nft-screen.more_from_this_collection")}</Box>
                        <Box><Link to={"/collection-get"} style={{textDecoration:'none',color:'#fff'}}>{t("nft-screen.view_all")}</Link></Box>
                    </Box>
                </React.Fragment>
            }
            <Box sx={{width:'100%',mt:'20px',mb:'30px'}}>
                {
                    !errorNFt && nftData && !loadingNFT && !error && !loading && 
                    <NFTs content={nftData} />
                }
                {
                    !errorNFt && loadingNFT && !loading && !error && !loading && 
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <LoaderCircle text={t("collection_buy_view.loading")} />
                    </Box>
                }
                {
                    !loadingNFT && errorNFt && !loading && !error && !loading && 
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                      <ErrorMessage error={errorNFt} />
                    </Box>
                }
            </Box>
        </Container>
    );
};

export default NFT;
