import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const LoginButton = styled(Button)(() => ({
    border:'1px solid #FEFFFF',
    background:'#081A43',
    color:'#FEFFFF',
    "&:hover":{
        color:'#35F8FF',
        border:'1px solid #35F8FF',
        background:'#081A43'
    },
    fontFamily:'BentonSansBBVA-Medium,sans-serif'
})); 

  


