import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MainContainer = styled(Box)(() => ({
    width:'100%',
    maxWidth:'100vw',
    background: '#003368',
    height: '100vh',

})); 

export const TopBarContent = styled(Box)(() => ({
    position: 'fixed',
    top:'0px',
    left: '0px',
    width: '100%',
    zIndex:2,
    background: '#1973B8'
})); 

export const BodyContent = styled(Box)(() => ({
    position:'relative',
    width:'100%',
    maxWidth:'100vw',
    overflowX:'hidden',
    overflowY:'auto',
    height:'100vh',
    zIndex:1
})); 