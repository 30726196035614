import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ButtonStyled from 'components/ButtonStyled';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoaderModal from 'components/LoaderModal';
import {useFetch} from 'hooks/useFetch';
import { sign } from 'services/Utils/signature';
import { Context } from 'hooks/WalletContext';
import TypeMintRadioButton from './components/TypeMintRadioButton';
import { useLocation , Link} from 'react-router-dom'
import CollectionCard from './components/CollectionCard';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarCustom from './components/CalendarCustom';
import TextBoxLink from './components/TextBoxLink';
import TextBoxFilterRange from './components/TextBoxFilterRange'
import CardNFT from './components/CardNFT';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Grid, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { initialize } from 'services/ERC721/initialize';
import CalendarAndTime from './components/CalendarAndTime';
import CompletePreMint from './components/CompletePreMint';
import { deploy } from 'services/ERC721/deploy';
import {
    CardContent,
    CardGrid2,
    CardList,
    ContainerCards,
    ContainerMessage,
    ContentArea,
    ContentCard,
    ContentCardGrid1,
    ContentFilter,
    ContentForm,
    ContentGoToCollection,
    FilterBody,
    FilterDetails,
    FilterDetailsContent,
    FilterForm,
    FilterTitle,
    LineDividerH,
    LineDividerV,
    MessageBoxContainer,
    PanelContainer,
    TextBox,
    TextTotal,
    TitleH2,
    TitleH3,
    TitleText
} from './style';
import { updateProjectKey } from 'services/collection/updateProjectKey';

const ShowCollection = ({content,loading,error}) =>{
    return (
        <React.Fragment>
            {
                loading ?
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'200px'}}>
                    <CircularProgress
                        size={35}
                        sx={{
                            color: '#fff'
                        }}
                    />
                </Box>
                :
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
                    <Box
                        sx={{
                            maxWidth:{xs:'100%',sm:'calc(60% - 10px)',md:'100%',lg:'100%',xl:'100%'},
                        }}
                    >
                        {
                            !error && content &&
                            <CollectionCard content={content} limit={1}/>
                        }
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}

ShowCollection.propTypes = {
    content: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any
}

const ChosePanelMint = ({
    typePanel,
    showOptionPanel,
    setShowOptionPanel,
    checkedSharing,
    setCheckedSharing,
    formMint,
    setFormMint,
    handleGetPreview,
    typeMintList,
    t,
    NFTLoading,
    load,
    activeTab,
    maxDate,
    handleResetValues,
    isOwner,
    handleConfirmWithOutContent,
    existData
    }) =>{

    React.useEffect(() => {
        handleResetValues();
    },[showOptionPanel])

    return (
        <React.Fragment>
            <center>
                {
                    (!existData && isOwner) &&
                    <TypeMintRadioButton 
                        options={typePanel} 
                        type={showOptionPanel}
                        setType={setShowOptionPanel} 
                        label={''}
                        name={"typeMint"} 
                        colorRadioButtons={"#43B02A"}
                        autoSelectedFirst={false}
                    />
                }
            </center>
            {
                showOptionPanel.typeMint == "without_preview" && isOwner &&
                <PanelContainer>
                    <Box sx={{width:'100%'}}>
                        <TitleText>{t("pre_mint_nft_massive.without_preview_text")}</TitleText>
                    </Box>
                    <center>
                        <TitleH3>{t("pre_mint_nft_massive.total_to_mint")}*</TitleH3>
                        <TextBox
                            type="number"
                            size={"small"}
                            label={""}
                            value={formMint.totalMint}
                            onChange={(e)=>{Number(e.target.value) >= 0 && setFormMint({...formMint,totalMint:Number(e.target.value)})}}
                        />
                    </center>
                    <center>
                        <TitleH3>{t("pre_mint_nft_massive.price")}*</TitleH3>
                        <TextBox
                            type="number"
                            size={"small"}
                            label={""}
                            value={formMint.price}
                            onChange={(e)=>{Number(e.target.value) >= 0 && setFormMint({...formMint,price:Number(e.target.value)})}}
                        />
                    </center>
                    <center>
                        <CalendarAndTime
                            date={formMint} 
                            setDate={setFormMint} 
                            name="dateMint"
                            maxDate={maxDate}
                        />
                    </center>
                    <center>
                        <ButtonStyled 
                            text={t("pre_mint_nft_massive.pre_mint_btn")}
                            onClick={handleConfirmWithOutContent}
                            isDisabled={formMint.totalMint <= 0 || formMint.price <= 0 }
                        />
                    </center>
                </PanelContainer>
            }
            {
                isOwner && (existData || showOptionPanel.typeMint == "with_preview") &&
                <PanelContainer>
                    {
                        !existData && 
                        <React.Fragment>
                            <Box sx={{width:'calc(100% - 5px)'}}>
                                <TitleText>{t("pre_mint_nft_massive.step_1")}</TitleText>
                            </Box>
                            <center>
                                <FormControlLabel
                                    sx={{color:'#fff',textAlign:'left',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
                                    control={<Checkbox sx={{fontFamily:'BentonSansBBVA-Medium,sans-serif',color:'#43B02A','&.Mui-checked': {color:'#43B02A'}}} value={checkedSharing}
                                    onChange={()=>{setCheckedSharing(!checkedSharing)}} />}
                                    label={t("pre_mint_nft_massive.confirm_step_1")}
                                />
                            </center>
                        </React.Fragment>
                    }
                    {checkedSharing && isOwner &&
                    <React.Fragment>
                        <center>
                            <h3 style={{margin:'0px 0px',color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>{t("pre_mint_nft_massive.check_link")}</h3>
                        </center>
                        <TextBoxLink 
                            link={formMint}
                            setLink={setFormMint}
                            value={formMint.link1}
                            nameLink="link1"
                            size={"large"}
                            width={"100%"}
                            label={t("pre_mint_nft_massive.sheet_file_url")}
                        />
                        <TextBoxLink 
                            link={formMint}
                            setLink={setFormMint}
                            value={formMint.link2}
                            nameLink="link2"
                            size={"large"}
                            width={"100%"} 
                            label={t("pre_mint_nft_massive.folder_rul")}
                        />
                        <center>
                            <ButtonStyled 
                                text={t("pre_mint_nft_massive.check_btn")} 
                                onClick={handleGetPreview}
                                isDisabled={NFTLoading || load || !formMint.isValid.link1 || !formMint.isValid.link2}
                            />
                        </center>
                        </React.Fragment>
                    }
                    {
                        activeTab == 2 && isOwner &&
                        <React.Fragment>
                            <Box display={"flex"} justifyContent={"center"}>
                                <TypeMintRadioButton 
                                    options={typeMintList} 
                                    type={formMint}
                                    setType={setFormMint} 
                                    label={t("pre_mint_nft_massive.mint_type.title")}
                                    name={"typeMint"} 
                                    colorRadioButtons={"#43B02A"}
                                />
                            </Box>
                            {   
                            formMint.typeMint == 3 && 
                            <CalendarCustom 
                                date={formMint} 
                                setDate={setFormMint} 
                                name="dateMint"
                                maxDate={maxDate}
                            />
                            }
                            {
                               ( formMint.typeMint == 2 ) &&
                                <Box sx={{m:'5px 0px'}}>
                                    <center>
                                        <TitleH3>{t("pre_mint_nft_massive.price")}*</TitleH3>
                                        <TextBox
                                            type="number"
                                            size={"small"}
                                            label={""}
                                            value={formMint.price}
                                            onChange={(e)=>{Number(e.target.value) >= 0 && setFormMint({...formMint,price:Number(e.target.value)})}}
                                        />
                                    </center>
                                </Box>
                            }
                        </React.Fragment>
                    }
                </PanelContainer>
            }
        </React.Fragment>
    )
}

ChosePanelMint.propTypes = {
    typePanel: PropTypes.array,
    showOptionPanel: PropTypes.object,
    setShowOptionPanel: PropTypes.func,
    checkedSharing: PropTypes.bool,
    setCheckedSharing: PropTypes.func,
    formMint: PropTypes.object,
    setFormMint: PropTypes.func,
    handleGetPreview: PropTypes.func,
    typeMintList: PropTypes.array,
    t: PropTypes.any,
    NFTLoading: PropTypes.bool,
    load: PropTypes.bool,
    activeTab: PropTypes.number,
    maxDate: PropTypes.any,
    handleResetValues: PropTypes.func,
    isOwner: PropTypes.bool,
    handleConfirmWithOutContent: PropTypes.func,
    existData: PropTypes.bool
}

const  MessageBox = ({msgSuccess,isOwner,loading,t}) =>{
    return (
        <React.Fragment>
            {
                !loading && 
                <React.Fragment>
                    <MessageBoxContainer>
                        <TitleH2>{msgSuccess}</TitleH2> 
                    </MessageBoxContainer>
                    {!isOwner && 
                    <MessageBoxContainer>
                        <TitleH2>{ t("pre_mint_nft_massive.message.you_dont_owner")}</TitleH2>
                    </MessageBoxContainer>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

MessageBox.propTypes = {
    msgSuccess: PropTypes.string,
    isOwner: PropTypes.bool,
    loading: PropTypes.bool,
    t: PropTypes.any,
}

const PreMintMassive = () => {
    const { t } = useTranslation("translate");
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const address = query.get('address');
    const {data} = React.useContext(Context);
    const [msgLoad,setMsgLoad] = React.useState('Loading...');
    const [msgSuccess,setMsgSuccess] = React.useState('');
    const [load,setLoad] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(1);
    const [items,setItems] = React.useState([]);
    const [checkedSharing,setCheckedSharing] = React.useState(false);
    const [checkedConfirm,setCheckedConfirm] = React.useState(false);
    let day = 86399000; // one day
    let nMonth = day * 30; // 30 days
    let maxDate = (new Date().getTime()) + (nMonth*3); // max date selected
    const [formMint,setFormMint] = React.useState({
        link1:'',
        link2:'',
        isValid:{
            link1:false,
            link2:false
        },
        typeMint:'',
        price:0,
        dateMint:'',
        totalMint:0 // only use if typePanel == without_preview;
    });
    const [showOptionPanel, setShowOptionPanel] = React.useState({
        typeMint:''
    });
    let typePanel = [
        {label: t("pre_mint_nft_massive.type_panel.with_content"), value:'with_preview'},
        {label: t("pre_mint_nft_massive.type_panel.without_content"), value:'without_preview'}
    ];
    let typeMintList = [
        // {label: t("pre_mint_nft_massive.mint_type.option_mint_with_preview"), value:'1'},
        {label: t("pre_mint_nft_massive.mint_type.option_mint_and_reveal"), value:'2'},
        // {label: t("pre_mint_nft_massive.mint_type.option_blind_mint"), value:'3'},
        {label: t("pre_mint_nft_massive.mint_type.option_mint_and_drop"), value:'4'},
    ];
    const [range,setRange] = React.useState({
        rangeBottom:0,
        rangeTop:0,
        limit:0
    })
    const [sliceBottom,setSliceBottom] = React.useState(0);
    const [sliceTop,setSliceTop] = React.useState(range.limit);  
    let urlCollection = `${process.env.REACT_APP_URL_API}/collection?address=${address}&domain=${process.env.REACT_APP_DOMAIN}`
    let {data:projectData, loading:projectLoading, error:projectError} = useFetch(urlCollection) //collection
    const [NFTLoading,setNFTLoading] = React.useState(false);
    const [NFTError,setNFTError] = React.useState(null);
    const [isOwner,setIsOwner] = React.useState(false);
    const [metadataFolder,setMetadataFolder] = React.useState(null);
    const [goToCollection,setGoToCollection] = React.useState(false);
    const [previewItems,setPreviewItems] = React.useState(0);
    const [existData,setExistData] = React.useState(false);
    const [newAddress,setNewAddress] = React.useState('');
    const [collectionComplete,setCollectionComplete] = React.useState(null);

    const handleRangeFilter = () =>{
        if((range.rangeTop-range.rangeBottom) < 0 || (range.rangeTop-range.rangeBottom) > 29){
            setSliceBottom(range.rangeTop);
            setSliceTop(range.rangeTop);
            setRange({...range,rangeBottom:range.rangeTop})
        }else{
            setSliceTop(range.rangeTop)
            setSliceBottom(range.rangeBottom);
            if(range.rangeBottom==range.rangeTop){
                setPreviewItems((range.rangeTop-range.rangeBottom)+1)
            }else{
                setPreviewItems((range.rangeTop-range.rangeBottom))
            }
        }        
    }

    const handleResetValues = () =>{
        setExistData(false)
        setActiveTab(1)
        setItems([])
        setActiveTab(1);
        setCheckedSharing(false); 
        setCheckedConfirm(false);
        setRange({
            rangeBottom:0,
            rangeTop:0,
            limit:0
        });
        setFormMint({
            link1:'',
            link2:'',
            isValid:{
                link1:false,
                link2:false
            },
            typeMint:'',
            price:0,
            dateMint:'',
            totalMint:0 // only use if typePanel == without_preview;
        });
    }
    
    const handlerRequestDrop = () =>{
        console.log("execute drop");
    }
    
    const selectActionRequest = async (res,body,action) =>{
        if(action == 'create' && res){
            setMsgSuccess("preview success") 
            setMetadataFolder(res.data.metadata_folder_url)
            handleResetValues();
            getDataIfExistsNfts();
        }else
        if(action == 'cancel'){
            handleResetValues();
        }else
        if(action == 'confirm'){
            console.log('res to confirm',res)
            console.log('metadataFolder',metadataFolder)
            console.log('items',items)
            setNFTError('');
            if(items.length > 0 && items[0].metadata && items[0].metadata.metadata_url) {
                let folder = "https://persea.mypinata.cloud/ipfs/"+items[0].metadata.metadata_url.split('/')[4]
                let transaction = await deploy( folder+"/", body.price,items.length,data.userAccount,data.provider).catch((error)=>{
                    if(error.code == '4001'){
                        setNFTError(t("message_errors.cancel_transaction"));
                    }else{
                        setNFTError(error);
                    }
                    setLoad(false)
                    setNFTLoading(false)
                });
                let msg = "Approve my intention of update project";
                let { signature , message } = await sign(msg,data.userAccount,data.provider).catch((error)=>{
                    if(error.code == '4001'){
                        setNFTError(t("message_errors.cancel_sign"));
                    }else{
                        setNFTError(error);
                    }
                    setLoad(false);
                    setNFTLoading(false);
                });
                await updateProjectKey(projectData[0].project_key, transaction._address, message, signature).catch((error)=>{
                    setLoad(false)
                    setNFTLoading(false)
                    setNFTError(error);
                });
                setNewAddress(transaction._address)
                handleResetValues();
                setGoToCollection(true);
                setMsgSuccess(t("pre_mint_nft_massive.message.success_pre_mint"))
                if(formMint.typeMint == 4){
                   handlerRequestDrop();
                }
                setCollectionComplete(true);
                handleResetValues();
                setLoad(false)
                setNFTLoading(false)
            }
            setGoToCollection(true);
            setMsgSuccess(t("pre_mint_nft_massive.message.success_pre_mint"))
        }
    }

    const handleRequest = async (body,action) =>{
        setGoToCollection(false);
        let url = `${process.env.REACT_APP_URL_API}/nft/massive?project_key=${address}&action=${action}&domain=${process.env.REACT_APP_DOMAIN}${action=='create'?'&create_from=sheet':''}`
        axios.post(url,body,{
            headers:{
                'Content-Type': 'text/plain;charset=utf-8',
            }
        }).then(async(res) => {
            setNFTError(null)
            selectActionRequest(res,body,action)
        }).catch(error=>{
            setGoToCollection(false);
            setLoad(false)
            setNFTLoading(false)
            if (error.response) {
                // Request made and server responded
                if(error.response.data.message) {
                    setNFTError(error.response.data.message)
                }
            } else if(error && String(error+'').includes("status code 500")){
                setNFTError(t("message_errors.try_again_later"))
            }else{
                setNFTError(error)
            }
        })
    }

    const selectActionIFExistNFTs = (res) =>{
        if(res && res.data && res.data.length > 0){
            setExistData(true)
            setActiveTab(2)
            let newItems = [];
            newItems = res.data;
            setItems(newItems);
            setSliceBottom(1);
            setFormMint({
                ...formMint,
                typeMint:'2'
            });
            if(newItems.length > 30){
                setRange({...range,
                    rangeBottom:1,
                    rangeTop:30,
                    limit:newItems.length 
                });
                setSliceTop(30)
            }else{
                setRange({...range,
                    rangeBottom:1,
                    rangeTop:newItems.length,
                    limit:newItems.length 
                });
                setSliceTop(newItems.length)
            }
        }else{
            handleResetValues();
            setExistData(false)
            setActiveTab(1)
        }
    }
    const getDataIfExistsNfts = async () =>{
        setLoad(true)
        setNFTLoading(true)
        setMsgLoad(t("pre_mint_nft_massive.looking_data"));
        setNFTError('')
        setExistData(false)
        setActiveTab(1)
        let url = `${process.env.REACT_APP_URL_API}/nft/massive?project_key=${address}&domain=${process.env.REACT_APP_DOMAIN}`
        axios.get(url).then(async(res) => {
            setLoad(false)
            setNFTLoading(false)
            selectActionIFExistNFTs(res);
        }).catch(er=>{
            setLoad(false)
            setNFTLoading(false)
            setExistData(false)
            setActiveTab(1)
            if(er && String(er+'').includes("status code 500")){
                setNFTError(t("message_errors.try_again_later"))
            }else{
                setNFTError(er)
            }
            handleResetValues();
            setNFTError(null)
        })
    }


    const handleGetPreview = async() =>{
        try{
            setLoad(true);
            setNFTLoading(true);
            setMsgLoad(t("pre_mint_nft_massive.message_loader.validating_data"));
            setNFTError('');
            let msg = 'Approve preview nfts"'
            let {signature , message} = await sign(msg,data.userAccount,data.provider).catch((error)=>{
                if(error.code == '4001'){
                    setNFTError(t("message_errors.cancel_sign"));
                }else{
                    setNFTError(error);
                }
                setLoad(false);
                setNFTLoading(false);
            });
            if(signature){
                let body = {
                    sheet_uri: formMint.link1,
                    folder_uri: formMint.link2,
                    signature: signature,
                    message: message,
                    blockchain_name: process.env.REACT_APP_NETWORK_NAME
                }
                handleRequest(body,"create");
            }
        }catch(err){
            console.log(err);
            setLoad(false);
            setNFTLoading(false);
        }
    }

    const handleConfirmWithOutContent = async() =>{
        try{
            setLoad(true);
            setNFTLoading(true);
            setMsgLoad(t("pre_mint_nft_massive.message_loader.confirm_pre_mint"));
            setNFTError('');
            let msg = 'Confirm massive mint';
            let {signature , message} = await sign(msg,data.userAccount,data.provider).catch((error)=>{
                if(error.code == '4001'){
                    setNFTError(t("message_errors.cancel_sign"));
                }else{
                    setNFTError(error);
                }
                setLoad(false)
                setNFTLoading(false);
            });
            if(signature && Number(formMint.totalMint) > 0){
                await initialize(address,data.userAccount, formMint.price, formMint.totalMint,data.provider).catch((error)=>{
                    setLoad(false)
                    setNFTLoading(false)
                    setNFTError(error);
                });
                let body = {
                    quantity: formMint.totalMint,
                    signature: signature,
                    message: message,
                    price:formMint.price,
                    sale_date: new Date(),
                    blockchain_name: process.env.REACT_APP_NETWORK_NAME
                }
                let url = `${process.env.REACT_APP_URL_API}/nft/massive?project_key=${address}&action=create&domain=${process.env.REACT_APP_DOMAIN}&create_from=quantity`
                axios.post(url,body,{
                    headers:{
                        'Content-Type': 'text/plain;charset=utf-8',
                    }
                }).then(async(res) => {
                    console.log(res)
                    handleResetValues();
                    getDataIfExistsNfts();
                    setMsgSuccess(t("pre_mint_nft_massive.message.success_pre_mint"))  
                }).catch(er=>{
                    setLoad(false)
                    setNFTLoading(false)
                    if(er && String(er+'').includes("status code 500")){
                        setNFTError(t("message_errors.try_again_later"))
                    }else{
                        setNFTError(er)
                    }
                })
            }else{
                setLoad(false)
                setNFTLoading(false)
            }
        }catch(err){
            console.log(err)
            setLoad(false)
            setNFTLoading(false)
        }
    }

    const handleConfirmWithContent = async() =>{
        try{
            setLoad(true);
            setNFTLoading(true);
            setMsgLoad(t("pre_mint_nft_massive.message_loader.confirm_pre_mint"));
            setNFTError('');
            let msg = 'Confirm massive mint';
            let {signature , message} = await sign(msg,data.userAccount,data.provider).catch((error)=>{
                if(error.code == '4001'){
                    setNFTError(t("message_errors.cancel_sign"));
                }else{
                    setNFTError(error);
                }
                setLoad(false)
                setNFTLoading(false);
            });
            if(signature){
                let body = formMint.typeMint == 4 ?
                {
                    signature: signature,
                    message: message,
                    blockchain_name: process.env.REACT_APP_NETWORK_NAME,
                    reveal_type: "at_date",
                    price: 0
                }
                :{
                    signature: signature,
                    message: message,
                    blockchain_name: process.env.REACT_APP_NETWORK_NAME,
                    reveal_type: "at_date",
                    //reveal_date: new Date(),
                    //sale_date: new Date(),
                    price: Number(formMint.price) == 0 ? 1 : Number(formMint.price)
                }
                handleRequest(body,"confirm");
            }else{
                setLoad(false);
                setNFTLoading(false);
            }
        }catch(err){
            console.log(err);
            setLoad(false);
            setNFTLoading(false);
        }
    }

    const handleCancel = async() =>{
        try{
            setLoad(true)
            setNFTLoading(true)
            setMsgLoad(t("pre_mint_nft_massive.message_loader.cancel_pre_mint"));
            setNFTError('');
            let msg = 'Cancel massive mint';
            let {signature , message} = await sign(msg,data.userAccount,data.provider).catch((error)=>{
                if(error.code == '4001'){
                    setNFTError(t("message_errors.cancel_sign"));
                }else{
                    setNFTError(error);
                }
                setLoad(false)
                setNFTLoading(false);
            });
            if(signature){
                let body = {
                    signature: signature,
                    message: message,
                    blockchain_name: process.env.REACT_APP_NETWORK_NAME
                }
                handleRequest(body,"cancel");
                setLoad(false)
                setNFTLoading(false)
            }
        }catch(err){
            console.log(err)
            setLoad(false)
            setNFTLoading(false)
        }
    }

   

    React.useEffect(() => {
        let customScroll = document.querySelector(".custom-scroll")
        if(customScroll){
            customScroll.scrollTo(0,0)
        }
        window.scrollTo(0,0)
    },[]);

    React.useEffect(()=>{
        if(msgSuccess != ''){
            let timeOut = setTimeout(()=>{
                setMsgSuccess('')
                clearTimeout(timeOut);
                return null;
            },19600)
        }
    },[msgSuccess]);

    const validateIfExitTx = (project) => {
        let band = null;
        if(project && project.reveal && project.reveal.is_ready){
            band = true;
        }else{
            band = false;
        }
      
        return band;
    }

    React.useEffect(()=>{
        console.log("debug projectData::",projectData);
        if(!projectLoading && projectData != null && projectData.length > 0 && projectData[0] != null && projectData[0].owner){
            
            if(!validateIfExitTx(projectData[0])){
                setCollectionComplete(false);
                if(data != null && data.userAccount != null && data.userAccount != 'undefined' && String(projectData[0].owner+'').toUpperCase() === String(data.userAccount+'').toUpperCase()){
                    setIsOwner(true); 
                    getDataIfExistsNfts();
                }else{
                    setIsOwner(true); 
                    getDataIfExistsNfts();
                }
            }else{
                setCollectionComplete(true);
            }
        }  
    },[projectLoading,data,projectData]);

    React.useEffect(()=>{
        if(sliceBottom == sliceTop){
            setPreviewItems((sliceTop-sliceBottom)+1)
            return ;
        }
        if(sliceBottom == 1 || sliceBottom == 0 ){
            if(sliceBottom == 0){
                setPreviewItems((sliceTop-sliceBottom))
            }else{
            setPreviewItems((sliceTop-sliceBottom)+1)
            }
        }else{
            setPreviewItems((sliceTop-sliceBottom))
        }
        
    },[sliceBottom,sliceTop]);


    return (
        <Box>
            <Container maxWidth="xl">
                    {
                        !projectLoading && projectData && projectData.length > 0 && !projectError && collectionComplete &&
                        <React.Fragment>
                            <br></br>
                            <CardContent>
                                <CompletePreMint addressOwner={data.userAccount} projectData={projectData[0]} t={t}>
                                    <ShowCollection  content={projectData} loading={projectLoading} error={projectError}/>
                                </CompletePreMint>
                            </CardContent>
                        </React.Fragment>
                    }
                    {
                        collectionComplete != null && !collectionComplete && 
                        <React.Fragment>
                            <br></br>
                                <CardContent>
                                    <Box sx={{mb:'10px'}}>  
                                        <ContentArea>
                                            <ContentForm>
                                                <center>
                                                    <TitleH2>{t("pre_mint_nft_massive.collection_selected")}</TitleH2>
                                                </center>
                                                <Box component='section' sx={{m:'0 auto',width:'90%',minHeight:'200px',maxHeight:'400px'}} >
                                                    <ShowCollection  content={projectData} loading={projectLoading} error={projectError}/>
                                                </Box>
                                                    <ChosePanelMint 
                                                        typePanel={typePanel}
                                                        showOptionPanel={showOptionPanel}
                                                        setShowOptionPanel={setShowOptionPanel}
                                                        checkedSharing={checkedSharing}
                                                        setCheckedSharing={setCheckedSharing}
                                                        formMint={formMint}
                                                        setFormMint={setFormMint}
                                                        handleGetPreview={handleGetPreview}
                                                        typeMintList={typeMintList}
                                                        t={t}
                                                        NFTLoading={NFTLoading}
                                                        load={load}
                                                        activeTab={activeTab}
                                                        maxDate={maxDate}
                                                        handleResetValues={handleResetValues}
                                                        isOwner={isOwner}
                                                        handleConfirmWithOutContent={handleConfirmWithOutContent}
                                                        existData={existData}
                                                    />
                                                    { activeTab == 2 && isOwner &&
                                                    <Box sx={{m:'30px 0px'}}>
                                                        <center>
                                                            <FormControlLabel
                                                                sx={{color:'#fff'}}
                                                                control={<Checkbox sx={{color:'#43B02A','&.Mui-checked': {color:'#43B02A'}}} value={checkedConfirm}
                                                                onChange={()=>{setCheckedConfirm(!checkedConfirm)}} />}
                                                                label={t("pre_mint_nft_massive.confirm_checkbox")}
                                                            />
                                                        </center>
                                                        <center>
                                                            <ButtonStyled 
                                                                text={t("pre_mint_nft_massive.pre_mint_btn")}
                                                                onClick={handleConfirmWithContent}
                                                                isDisabled={formMint.price <= 0  && formMint.typeMint == '2' || formMint.typeMint == '3' && formMint.dateMint == '' || items.length == 0 || items == null || items == undefined || !checkedConfirm }
                                                            />
                                                        </center>
                                                        <br/>
                                                        <center>
                                                            <ButtonStyled 
                                                                text={t("pre_mint_nft_massive.cancel_btn")}
                                                                onClick={handleCancel}
                                                                isDisabled={items.length == 0 || items == null || items == undefined}
                                                            />
                                                        </center>
                                                    </Box>
                                                    }
                                            </ContentForm>
                                            <LineDividerV orientation="vertical"  flexItem />
                                            <LineDividerH orientation="horizontal"  flexItem />
                                            <ContentFilter>
                                                <Divider sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},background:'#fff',m:'10px 0px'}} />
                                                <center>
                                                    <FilterTitle>{t("pre_mint_nft_massive.preview.title")}</FilterTitle>
                                                </center>
                                                <FilterBody>
                                                    <Box>
                                                        <FilterForm>
                                                            <TextBoxFilterRange 
                                                                range={range}
                                                                setRange={setRange}
                                                                nameRange="rangeBottom"
                                                                value={range.rangeBottom}
                                                                size={"small"}
                                                                label={"MIN"}
                                                                width={"100%"}
                                                                maxNumber={items.length}
                                                            />
                                                            <h3>{t("pre_mint_nft_massive.preview.to")}</h3>
                                                            <TextBoxFilterRange 
                                                                range={range}
                                                                setRange={setRange}
                                                                nameRange="rangeTop"
                                                                value={range.rangeTop}
                                                                size={"small"}
                                                                label={"MAX"}
                                                                width={"100%"}
                                                                maxNumber={items.length}
                                                            />
                                                            <ButtonStyled 
                                                                width={"250px"}
                                                                text={t("pre_mint_nft_massive.preview.view_btn")}
                                                                onClick={handleRangeFilter}
                                                                isDisabled={items.length == 0 || items == null || items == undefined}
                                                            />
                                                        </FilterForm>
                                                    </Box>
                                                    {items.length > 0 && 
                                                    <FilterDetailsContent>
                                                        <FilterDetails>
                                                            <Box><b>{t("pre_mint_nft_massive.preview.total_items")}: </b>{items.length}</Box>
                                                            <Box><b>{t("pre_mint_nft_massive.preview.from")}: </b>{sliceBottom} <b>{t("pre_mint_nft_massive.preview.to")}: </b>{sliceTop}</Box>
                                                        </FilterDetails>
                                                    </FilterDetailsContent>
                                                    }
                                                </FilterBody>
                                                <Box>
                                                    <Divider sx={{background:'#fff'}} />
                                                    {
                                                    !load && NFTError &&
                                                    <ContainerMessage>  
                                                        <h3>{NFTError+''}</h3>
                                                    </ContainerMessage>
                                                    }
                                                    <MessageBox msgSuccess={msgSuccess} isOwner={isOwner} loading={projectLoading} t={t}/>
                                                    {
                                                        goToCollection && projectData && projectData.length > 0 && projectData[0] &&
                                                        <ContentGoToCollection>
                                                            <Button 
                                                                component={Link} 
                                                                to={`/collection-get?address=${newAddress}`}
                                                                sx={{
                                                                    background:'#081A43',
                                                                    color:'#FEFFFF',
                                                                    padding:'1rem',
                                                                    borderRadius:'6px',
                                                                    "&:hover":{
                                                                        color:'#35F8FF',
                                                                        border:'1px solid #35F8FF',
                                                                        background:'#081A43'
                                                                    },
                                                                    fontFamily:'BentonSansBBVA-Medium,sans-serif'
                                                                }}
                                                            >
                                                                 {t("pre_mint_nft_massive.go_to_collection")}
                                                            </Button>
                                                        </ContentGoToCollection>
                                                    }
                                                    {
                                                    (items.length > 0 || activeTab == 2) &&
                                                    <React.Fragment>
                                                        <center>
                                                            <TextTotal>Items ( {previewItems} {' / '+items.length} )</TextTotal>
                                                        </center>
                                                        <Box
                                                            sx={{p:'5px'}}
                                                        >
                                                            <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
                                                                <Grid 
                                                                    container 
                                                                    columns={{xs:12,sm:4, md:4, lg:3, xl:3}}
                                                                    rowSpacing={4} 
                                                                    spacing={{ xs: 2, sm:2,md: 2, lg: 2, xl: 2 }}
                                                                >
                                                                    {
                                                                        items.slice(sliceBottom == 1 || sliceBottom == 0 ?0:sliceBottom == sliceTop ? sliceBottom-1:sliceBottom,sliceTop).map((item,index)=>{
                                                                            return (
                                                                                <Grid 
                                                                                    key={index} 
                                                                                    item 
                                                                                    xs={12}
                                                                                    sm={2} 
                                                                                    md={2} 
                                                                                    lg={1} 
                                                                                    xl={1}
                                                                                    sx={{
                                                                                        width:'100%',
                                                                                        
                                                                                    }}
                                                                                >
                                                                                    <ContentCard>
                                                                                        <ContentCardGrid1>
                                                                                            <Box sx={{width:'100%'}}>
                                                                                                <CardNFT item={item}/>
                                                                                            </Box>
                                                                                            
                                                                                            <Box sx={{maxWidth:'calc(70% -10px)',p:'5px',color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif',wordWrap:'break-word',height:'auto'}}>
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        width:'100%'
                                                                                                    }}
                                                                                                >
                                                                                                    <small>
                                                                                                        <b>{t("pre_mint_nft_massive.cards.name")}: </b><br></br>
                                                                                                        {item.metadata.json_data.name}
                                                                                                    </small>
                                                                                                </Box>
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        width:'100%'
                                                                                                    }}
                                                                                                >
                                                                                                    <small>
                                                                                                        <b>{t("pre_mint_nft_massive.cards.description")}: </b><br></br>
                                                                                                        {item.metadata.json_data.description}
                                                                                                    </small>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </ContentCardGrid1>
                                                                                        <Box>
                                                                                            <Box sx={{width:'100%',color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                                                                                                <small>
                                                                                                    <b>{t("pre_mint_nft_massive.cards.attributes")}: </b>
                                                                                                </small>
                                                                                            </Box>
                                                                                            <CardGrid2>
                                                                                                {(item.metadata && item.metadata.json_data && item.metadata.json_data.attributes)?.map((attribute, index) => (
                                                                                                    attribute.trait_type != 'Description' &&  attribute.trait_type != 'Name' && attribute.trait_type != 'Number' &&
                                                                                                    <CardList key={index}>
                                                                                                        <ListItemText 
                                                                                                            primaryTypographyProps={{style: {color:'#fff',fontSize:'13px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}}
                                                                                                            secondaryTypographyProps={{style: {color:'#B9B9B9',fontSize:'12px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}}
                                                                                                            primary={attribute.trait_type ? (attribute.trait_type) : ''} 
                                                                                                            secondary={attribute.trait_type ? (attribute.value) : ''}
                                                                                                            sx={{textAlign:'center',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
                                                                                                        />
                                                                                                    </CardList>
                                                                                                ))}
                                                                                            </CardGrid2>
                                                                                        </Box>
                                                                                    </ContentCard>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Container>
                                                            <ContainerCards sx={{display:'none'}}>
                                                                {
                                                                    items.slice(sliceBottom == 1 || sliceBottom == 0 ?0:sliceBottom == sliceTop ? sliceBottom-1:sliceBottom,sliceTop).map((item,index)=>{
                                                                        return (
                                                                            <ContentCard  key={index}
                                                                                sx={{
                                                                                    boxSizing: 'border-box',
                                                                                    
                                                                                }}
                                                                            >
                                                                                <ContentCardGrid1>
                                                                                    <Box sx={{width:'100%'}}>
                                                                                        <CardNFT item={item}/>
                                                                                    </Box>
                                                                                    
                                                                                    <Box sx={{maxWidth:'calc(70% -10px)',p:'5px',color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif',wordWrap:'break-word',height:'auto'}}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                width:'100%'
                                                                                            }}
                                                                                        >
                                                                                            <small>
                                                                                                <b>{t("pre_mint_nft_massive.cards.name")}: </b><br></br>
                                                                                                {item.metadata.json_data.name}
                                                                                            </small>
                                                                                        </Box>
                                                                                        <Box
                                                                                            sx={{
                                                                                                width:'100%'
                                                                                            }}
                                                                                        >
                                                                                            <small>
                                                                                                <b>{t("pre_mint_nft_massive.cards.description")}: </b><br></br>
                                                                                                {item.metadata.json_data.description}
                                                                                            </small>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </ContentCardGrid1>
                                                                                <Box>
                                                                                    <Box sx={{width:'100%',color:'#fff',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                                                                                        <small>
                                                                                            <b>{t("pre_mint_nft_massive.cards.attributes")}: </b>
                                                                                        </small>
                                                                                    </Box>
                                                                                    <CardGrid2>
                                                                                        {(item.metadata && item.metadata.json_data && item.metadata.json_data.attributes)?.map((attribute, index) => (
                                                                                            attribute.trait_type != 'Description' &&  attribute.trait_type != 'Name' && attribute.trait_type != 'Number' &&
                                                                                            <CardList key={index}>
                                                                                                <ListItemText 
                                                                                                    primaryTypographyProps={{style: {color:'#fff',fontSize:'13px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}}
                                                                                                    secondaryTypographyProps={{style: {color:'#B9B9B9',fontSize:'12px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}}
                                                                                                    primary={attribute.trait_type ? (attribute.trait_type) : ''} 
                                                                                                    secondary={attribute.trait_type ? (attribute.value) : ''}
                                                                                                    sx={{textAlign:'center',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
                                                                                                />
                                                                                            </CardList>
                                                                                        ))}
                                                                                    </CardGrid2>
                                                                                </Box>
                                                                            </ContentCard>
                                                                        )
                                                                    })
                                                                }
                                                            </ContainerCards>
                                                        </Box>
                                                    </React.Fragment>
                                                    }
                                                </Box>
                                            </ContentFilter>
                                        </ContentArea>
                                    </Box>
                                </CardContent>
                    
                        </React.Fragment>
                    }
                    
                <LoaderModal
                    text={msgLoad}
                    isOpen={NFTLoading||load}
                    textColor='#fff'
                    spinnerColor='#fff'
                />
            </Container>
        </Box>
    );
};

export default PreMintMassive;