export const nfts = [
  {
    "project_key": "0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
    "project_key_format": "0xBa...C70c",
    "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
    "thumb_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "transaction": "0x5f73ed1f45aeb04faa4a5709f38c4f45501e0a0688e23c819895b54c5bd758f5",
    "license": 1,
    "status": 1,
    "token_id": 1,
    "views": "98",
    "stock": "1",
    "is_owner": false,
    "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
    "short_owner": "0x30...41f3",
    "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "short_creator": "0x01...9014",
    "creator_avatar": "",
    "on_auction": false,
    "tags": [],
    "categories": [],
    "highest_bid": {
      "price": 0,
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      }
    },
    "count_bid": 0,
    "txs": [],
    "init_price": "0",
    "finish_price": "0",
    "on_sale": false,
    "amount_top": "0",
    "blockchain": {
      "name": "AVALANCHE FUJI ARTCRYPTED",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
    },
    "metadata": {
      "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "image_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "metadata_url": "https://persea.mypinata.cloud/ipfs/QmYTtQ6FJanDMgYzQBL5Dp3NtmRNDBzqVPW9Y78kJQ25NF",
      "is_video": false,
      "short_metadata_url": "https://...Q25NF",
      "json_data": {
        "name": "Fuerte hermosura",
        "image": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
        "external_url": "",
        "description": "test!",
        "isVideo": false,
        "attributes": [
          {
            "trait_type": "1",
            "value": "Toon"
          }
        ]
      }
    },
    "sale":{
      "coin":"ETH",
      "price":"0.01"
    },
    "user": {
      "registered": true,
      "balances": [],
      "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "domain": "artcrypted.com",
      "username": "0x016b76...9014",
      "email": "",
      "about": "",
      "twitter": "",
      "homepage": "",
      "telegram": "",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "theme": 1,
      "expert": false,
      "first_seen": "2022-04-08 18:45:35.178152",
      "last_seen": "2022-04-08 18:45:35.178152",
      "role": 1,
      "followers": 357
    }
  },
  {
      "project_key": "0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "project_key_format": "0xFe...09e5",
      "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "transaction": "0xa5116de3c377c0ef7e5cb12c97e76a497c44ea28c97cf6fb5f49a0131cd1fd31",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "14",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTjxQXSkertECQCTWCEqVEqb4kceXaF9idhr5ov4vsvSW",
        "is_video": false,
        "short_metadata_url": "https://...vsvSW",
        "json_data": {
          "name": "Lago",
          "image": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "cine"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"1.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 175
      }
  },
  {
      "project_key": "0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "project_key_format": "0xBa...C70c",
      "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "transaction": "0x5f73ed1f45aeb04faa4a5709f38c4f45501e0a0688e23c819895b54c5bd758f5",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "98",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmYTtQ6FJanDMgYzQBL5Dp3NtmRNDBzqVPW9Y78kJQ25NF",
        "is_video": false,
        "short_metadata_url": "https://...Q25NF",
        "json_data": {
          "name": "Fuerte hermosura",
          "image": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "Toon"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"0.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 357
      }
  },
  {
      "project_key": "0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "project_key_format": "0xFe...09e5",
      "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "transaction": "0xa5116de3c377c0ef7e5cb12c97e76a497c44ea28c97cf6fb5f49a0131cd1fd31",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "14",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTjxQXSkertECQCTWCEqVEqb4kceXaF9idhr5ov4vsvSW",
        "is_video": false,
        "short_metadata_url": "https://...vsvSW",
        "json_data": {
          "name": "Lago",
          "image": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "cine"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"1.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 175
      }
  },
  {
    "project_key": "0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
    "project_key_format": "0xBa...C70c",
    "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
    "thumb_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
    "transaction": "0x5f73ed1f45aeb04faa4a5709f38c4f45501e0a0688e23c819895b54c5bd758f5",
    "license": 1,
    "status": 1,
    "token_id": 1,
    "views": "98",
    "stock": "1",
    "is_owner": false,
    "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
    "short_owner": "0x30...41f3",
    "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "short_creator": "0x01...9014",
    "creator_avatar": "",
    "on_auction": false,
    "tags": [],
    "categories": [],
    "highest_bid": {
      "price": 0,
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      }
    },
    "count_bid": 0,
    "txs": [],
    "init_price": "0",
    "finish_price": "0",
    "on_sale": false,
    "amount_top": "0",
    "blockchain": {
      "name": "AVALANCHE FUJI ARTCRYPTED",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
    },
    "metadata": {
      "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "image_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "metadata_url": "https://persea.mypinata.cloud/ipfs/QmYTtQ6FJanDMgYzQBL5Dp3NtmRNDBzqVPW9Y78kJQ25NF",
      "is_video": false,
      "short_metadata_url": "https://...Q25NF",
      "json_data": {
        "name": "Fuerte hermosura",
        "image": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
        "external_url": "",
        "description": "test!",
        "isVideo": false,
        "attributes": [
          {
            "trait_type": "1",
            "value": "Toon"
          }
        ]
      }
    },
    "sale":{
      "coin":"ETH",
      "price":"0.01"
    },
    "user": {
      "registered": true,
      "balances": [],
      "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "domain": "artcrypted.com",
      "username": "0x016b76...9014",
      "email": "",
      "about": "",
      "twitter": "",
      "homepage": "",
      "telegram": "",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "theme": 1,
      "expert": false,
      "first_seen": "2022-04-08 18:45:35.178152",
      "last_seen": "2022-04-08 18:45:35.178152",
      "role": 1,
      "followers": 357
    }
  },
  {
      "project_key": "0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "project_key_format": "0xFe...09e5",
      "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "transaction": "0xa5116de3c377c0ef7e5cb12c97e76a497c44ea28c97cf6fb5f49a0131cd1fd31",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "14",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTjxQXSkertECQCTWCEqVEqb4kceXaF9idhr5ov4vsvSW",
        "is_video": false,
        "short_metadata_url": "https://...vsvSW",
        "json_data": {
          "name": "Lago",
          "image": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "cine"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"1.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 175
      }
  },
  {
      "project_key": "0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "project_key_format": "0xBa...C70c",
      "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
      "transaction": "0x5f73ed1f45aeb04faa4a5709f38c4f45501e0a0688e23c819895b54c5bd758f5",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "98",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xBa3C8e6FB4560C9686B35F7E0773B0828e6aC70c",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmYTtQ6FJanDMgYzQBL5Dp3NtmRNDBzqVPW9Y78kJQ25NF",
        "is_video": false,
        "short_metadata_url": "https://...Q25NF",
        "json_data": {
          "name": "Fuerte hermosura",
          "image": "https://persea.mypinata.cloud/ipfs/QmTPguU82kUL77c8EyoahzbHr89D4Kc381nCXqHCscwd6M",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "Toon"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"0.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 357
      }
  },
  {
      "project_key": "0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "project_key_format": "0xFe...09e5",
      "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "transaction": "0xa5116de3c377c0ef7e5cb12c97e76a497c44ea28c97cf6fb5f49a0131cd1fd31",
      "license": 1,
      "status": 1,
      "token_id": 1,
      "views": "14",
      "stock": "1",
      "is_owner": false,
      "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
      "short_owner": "0x30...41f3",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {
        "price": 0,
        "blockchain": {
          "name": "AVALANCHE FUJI ARTCRYPTED",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
        }
      },
      "count_bid": 0,
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": false,
      "amount_top": "0",
      "blockchain": {
        "name": "AVALANCHE FUJI ARTCRYPTED",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
      },
      "metadata": {
        "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTjxQXSkertECQCTWCEqVEqb4kceXaF9idhr5ov4vsvSW",
        "is_video": false,
        "short_metadata_url": "https://...vsvSW",
        "json_data": {
          "name": "Lago",
          "image": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
          "external_url": "",
          "description": "test!",
          "isVideo": false,
          "attributes": [
            {
              "trait_type": "1",
              "value": "cine"
            }
          ]
        }
      },
      "sale":{
        "coin":"ETH",
        "price":"1.01"
      },
      "user": {
        "registered": true,
        "balances": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "artcrypted.com",
        "username": "0x016b76...9014",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-04-08 18:45:35.178152",
        "last_seen": "2022-04-08 18:45:35.178152",
        "role": 1,
        "followers": 175
      }
  }
]