import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import ModalShare from 'components/ModalShare';
import Banner from './components/Banner';
import Information from './components/Information';
import Share from './components/Share';
import PropTypes from 'prop-types'

/**
 * It's a function that returns a React Fragment that contains a Banner component, a Share component,
 * and a ModalShare component.
 * @param {Object} content - An object that contains the information of the collection.
 * @param {String} address - A string that contains the address of the collection.
 * @returns A React Fragment with a Banner, Share, and ModalShare component.
 */
const CollectionHeader = ({content}) => {
    const [share, setShare] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(content && content.banner_url ? content.banner_url:'')
    const fileRef = useRef(null);
    const handleUpload = () => {
        fileRef.current.click();
    }

    const handleChangeUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBackgroundImage(reader.result)
        }
    }
    console.log('CollectionHeader content:', content)
    return (
        <Box sx={{width:'100%',minHeight:'200px'}}>
            {
                content &&  content.thumb_url &&
                <React.Fragment>
                     <Banner background={backgroundImage}  content={content} isVideo={content.is_video} randomThumb={content.thumb_url} >
                        <Information content={content} />
                    </Banner>
                    <Share 
                        content={content}
                        func={handleUpload} 
                        setState={setShare} 
                        onChange={handleChangeUpload}
                        fileRef={fileRef}
                    />
                    <ModalShare url={`${window.location}`} open={share} setOpen={setShare} />
                </React.Fragment>
            }
        </Box>
    )
}

CollectionHeader.propTypes = {
    content: PropTypes.object.isRequired,
    address: PropTypes.string.isRequired,
}

export default CollectionHeader