import React, { useEffect, useState ,useContext} from 'react'
import { CardMedia, Box, Button, CardContent,  Container, Grid, Typography, Tooltip } from '@mui/material'
import {BigTitle, DisplayOver,BackgroundNewCard} from './styles/styles'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { isMinted } from 'services/ERC721/isMinted';
import { StatusTx } from 'hooks/StatusTxContext';
import { getThumbnail } from 'services/Thumbail/getThumbnail';
import Web3 from 'web3';
import IconBlockies from 'components/IconBlockies/IconBlockies';

const initialState = {
    days: 0, 
    hours: 0,
    minutes: 0,
    seconds: 0,
}
const CounterAuction = ({item})=>{
    const {t} = useTranslation("translate");
    const [counters, setCounters] = useState(initialState)
    let isFirst = true;

    const countDown = () => {
        setInterval(function () {
            let realTime = 0;

            if(item && (item.time_live+item.start_date) > 0 && !item.finish_date){
                realTime = (item.time_live+item.start_date)
            }

            
            if(item && item.finish_date > 0){
                realTime = item.finish_date;
            }

           

            

            if(item && realTime > 0) {
                let countDownDate = realTime * 1000;
                let now = new Date().getTime();
                let distance = countDownDate - now;
                let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000)
                if(distance >= 0) { 
                    setCounters({
                        ...counters,
                        days: days,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds
                    })
                }
            }
        }, 1000);
    }

    useEffect(() => {
        if(isFirst) {
            isFirst = false;
            countDown();
        }
    } ,[])

    return (
        <Box>
            <Box>
                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom 
                    sx={{
                        fontSize:'15px',
                        color:'#b3b3b3',
                        fontWeight: 600,
                    }}
                >
                {t("home.ends_in")}
                </Typography>
            </Box>
            <Box
                display='flex'
                sx={{
                    fontSize:'18px',
                    color:'#fff',
                    fontWeight: 600,
                    gap:'10px',
                    "@media (min-width: 320px)": {
                        fontSize:'13px',
                        fontWeight: 200,
                        gap:'5px',
                    }
                }}
            >
                <Box>{((counters.days * 24) + counters.hours).toFixed(0)}{t("cards.auction.short_hour_text")}</Box>
                <Box>{counters.minutes}{t("cards.auction.short_minute_text")}</Box>
                <Box>{counters.seconds}{t("cards.auction.short_second_text")}</Box>
            </Box>
        </Box>
    )
}


const Card = ({item}) =>{
    const { statusTx } = useContext(StatusTx);
    const {t} = useTranslation("translate");
    return (
        <BackgroundNewCard>
            {
                item && item.metadata.is_video  ?
                <CardMedia
                    className={'card-collection'}
                    component={'video'}
                    src={item && item.thumb_url}
                    autoPlay
                    loop
                    muted
                    sx={{
                        position:'relative',
                        borderRadius: '8px',
                        height:'100%',
                        width:'100%',
                        margin:'0 auto'
                    }}
                />
                :
                <CardMedia
                    className={'card-collection'}
                    component={'img'}
                    src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                    sx={{
                        position:'relative',
                        borderRadius: '8px',
                        height:'100%',
                        width:'100%',
                        margin:'0 auto',
                    }}
                />
            }
            <Box
                sx={{
                    position:'absolute',
                    width:'100%',
                    height:'100%',
                    top:'0px',
                    left:'0px',
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 25.53%, rgba(0, 0, 0, 0.5) 67.17%, #000000 109.78%)',
                }}
            />
            
            <DisplayOver>
                <BigTitle>
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'flex-start'
                        }}
                    >
                        {
                            isMinted(item) && 
                            <Tooltip title={item.metadata.json_data.name+''}  placement="top">
                                <Box
                                    sx={{
                                        width:'auto',
                                        boxSizing:'border-box',
                                        maxWidth:'100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:'100%',
                                            boxSizing:'border-box',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            fontWeight:600, 
                                            fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                            fontSize:'30px',
                                            color:'#FFF',
                                            backgroundColor:'rgba(0, 0, 0, 0.2)',
                                            padding:'0.5rem',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        {item.metadata.json_data.name}
                                    </Box>
                                </Box>
                            </Tooltip>
                        }
                    </Box>
                </BigTitle>
            </DisplayOver>
            <Box
                sx={{
                    height: "auto",
                    bottom: "0",
                    left: "0",
                    position: "absolute",
                    width: "100%",
                    zIndex: 2,
                    backgroundColor: "transparent",
                    boxSizing: "border-box"
                }}
            >
                <CardContent
                    sx={{
                        borderRadius:'0 0 8px 8px',
                        height:'auto',
                        width:'100%',
                        display:'flex',
                        flexDirection:'column',
                        padding:'0px 15px',
                        margin:'0px'

                    }}
                >
                    <Box
                        sx={{
                            display:'grid',
                            gridTemplateColumns:'1fr'
                        }}
                    >
                        <Box
                            display='flex'
                            sx={{
                                gap:'5px',
                                width:'100%'
                            }}
                        >
                            <Box component='span'>
                                <IconBlockies address={item?.user?.main_key} />
                            </Box>
                            <Box component='span' sx={{display:'none'}}>
                                <Typography 
                                    variant="overline" 
                                    display="block" 
                                    gutterBottom 
                                    component='span'
                                    sx={{
                                        marginTop:'3px',
                                        fontSize:'25px',
                                        color:'#FEFFFF',
                                        fontWeight: 'bold',
                                        cursor:'pointer',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '13rem',
                                        display:'inline-block',
                                        fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                        "@media (min-width: 320px)": {
                                            fontSize:'13px',
                                            fontWeight: 200,
                                        }
                                    }}
                                >
                                   {
                                    item && item.user && item.user.username && !Web3.utils.isAddress(String(item.user.username).toLowerCase()) ? 
                                    item.user.username
                                    :
                                    item.user.short_main_key
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Box>
            <Box
                sx={{
                    position:'absolute',
                    bottom:'0px',
                    right:'0px',
                }}
            >
                <Box
                    sx={{
                        display:'none',
                        width:'auto',
                        justifyContent: 'flex-end',
                        padding:'15px',
                        fontWeight:'bold',
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                   {
                    !statusTx && String(item.user.main_key+'').toUpperCase() == String(item.collection.user.main_key+'').toUpperCase() && t("collection.claim_text")
                   }
                </Box>
            </Box>
        </BackgroundNewCard>
    )

}

const CollectionNFTs = ({showBtnAll, content, limit,NFTLoading}) => {
    const {t} = useTranslation("translate");


    if(!NFTLoading && (content == null || (content && content.length === 0) || content == "")) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',mt:'20px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
            >
                <Typography variant='h4' sx={{color:'#fff'}}>
                    {t("collection.any_results")}
                </Typography>
            </Box>
     
        )
    }

    if(!NFTLoading && (content != null || (content && content.length > 0) || content != "") && ( content[0] && content[0].reveal && content[0].reveal.confirmed && content[0].reveal.reveal_date_time > (new Date().getTime() / 1000))) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',mt:'20px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
            >
                <Typography variant='h4' sx={{color:'#fff'}}>
                    {t("collection.any_results")}
                </Typography>
            </Box>
     
        )
    }

    if(!NFTLoading && (content != null || (content && content.length > 0) || content != "") && ( content[0] && !content[0].reveal.confirmed)) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',mt:'20px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}
            >
                <Typography variant='h4' sx={{color:'#fff'}}>
                    {t("collection.any_results")}
                </Typography>
            </Box>
     
        )
    }
    
    return (
        <>
            <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>

                <Grid 
                    container 
                    columns={{xs:12,sm:12, md:12, lg:12, xl:12}}
                    rowSpacing={4} 
                    spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                >
                    {content?.slice(0, limit).map((item, index)=>(
                        item.reveal.confirmed  &&
                    <Grid 
                        key={index} 
                        item 
                        xs={12}
                        sm={6} 
                        md={4} 
                        lg={4} 
                        xl={4}
                        sx={{
                            width:'100%',
                            minHeight:'380px',
                            maxHeight:'400px'
                        }}
                    >
                        <Link 
                            to={`/nft?address=${item.collection_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                            style={{
                                textDecoration: 'none',
                                minHeight:'400px'
                            }}
                        >
                           <Card item={item} />
                        </Link>
                    </Grid>))}
                </Grid>
            </Container>
            {showBtnAll &&
            (<Container 
                maxWidth='sm' 
                sx={{
                    display: 'flex',
                    marginTop:'2rem',
                    justifyContent:'center',
                }}
            >
                <Button
                    variant="outlined"
                    type="button"
                    sx={{
                        borderRadius:'9999px',
                        backgroundColor:'#fff',
                        color:'#000',
                        border:'1px solid #e3e3e3',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        fontSize:'18px',
                        '&:hover':{
                            backgroundColor:'#000',
                            transition:'background-color 0.3s ease-in-out',
                            color:'#fff',
                            border:'1px solid #000',
                        }
                    }}
                >
                    View all NFTs
                </Button>
            </Container>)}
        </>
    )
}

CollectionNFTs.defaultProps = {
    limit: 8,
    showBtnAll: false,
    content: [],
    NFTLoading: false,
}

CollectionNFTs.propTypes = {
    showBtnAll: PropTypes.bool,
    content: PropTypes.array, 
    limit: PropTypes.number,
    openFilters: PropTypes.bool,
    NFTLoading: PropTypes.bool,
}

Card.propTypes = {
    item: PropTypes.object
}

CounterAuction.propTypes = {
    item: PropTypes.object
}

export default CollectionNFTs
