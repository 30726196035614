import React from "react";
import Carousel from "react-multi-carousel";
import PropTypes from "prop-types";
import CardAuction from "./components/CardAuction";
import CardNFT from "./components/CardNFT";
import CardProfile from "./components/CardProfile";
import CardCategories from "./components/CardCategories";
import CardCurators from "./components/CardCurators";
import CardCollection from "./components/CardCollection";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";

const ButtonGroup = ({ next, previous,...rest}) => {
  const { carouselState: { currentSlide,deviceType,totalItems}} = rest;
  const [show,setShow] = React.useState(false);
  const [showBack,setShowBack] = React.useState(false);
  const [showNext,setShowNext] = React.useState(false);
  
  /* This is a custom button group for the carousel. It is used to show the next and previous buttons. */
  React.useEffect(()=>{
      if((deviceType =='mobile' || deviceType =='tablet')){
        setShow(false)
      }else{
        setShow(true)
      }
  },[deviceType])

  const init = () => {
    if(currentSlide == 4){
      setShowNext(false)
      return null;
    }
    if(currentSlide < 1){
      setShowBack(false)
      if(totalItems >= 4){
        setShowNext(true)
      }else{
        setShowNext(false)
      }
    }else{
      setShowBack(true);
      if(totalItems >= 4){
        setShowNext(true)
      }else{
        setShowNext(false)
      }
    }
  }

  React.useEffect(()=>{
    init();
  },[currentSlide,totalItems])

  return (
    <React.Fragment>
      {show ?
      <React.Fragment>
        {
          showBack &&
          <Box
            onClick={() => previous()}
            draggable="false"
            sx={{
              position: "absolute",
              top:'0px',
              left: "0px",
              width: "30px",
              height: "100%",
              backgroundColor: "transparent",
              fontSize: "60px",
              color: "rgb(168,88,216)",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "10px 0px 0px 10px"
            }}
          >
            <Box
              sx={{
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                KhtmlUserSelect: 'none',
                msUserSelect:'none'
              }}
            >
              {"<"}
            </Box>
          </Box>
        }
        {
          showNext &&
          <Box
            onClick={() => next()}
            sx={{
              position: "absolute",
              top:'0px',
              right: "0px",
              width: "30px",
              height: "100%",
              backgroundColor: "transparent",
              fontSize: "60px",
              color: "rgb(168,88,216)",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "0px 10px 10px 0px"
            }}
          >
            <Box
              sx={{
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                KhtmlUserSelect: 'none',
                msUserSelect:'none'
              }}
            >
              {">"}
            </Box>
          </Box>
        }
        </React.Fragment>
        :
        null
      }
    </React.Fragment>
  );
};

ButtonGroup.propTypes = {
    next: PropTypes.any,
    previous: PropTypes.any,
    res: PropTypes.any
}


const CarouselSimple = ({ deviceType ,content,width,type}) => {

  const responsive = {
    big:{
      breakpoint: { max: 4000, min: 3000},
      items: 2,
      paritialVisibilityGutter:0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter:0
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      paritialVisibilityGutter: 2
    }
  };

  const [images, setImages] = React.useState([]);

  const init = async() =>{
    if(deviceType=='mobile' || deviceType =='tablet'){
      if(content && content.length > 3 && type !== 'categories') {
        setImages(content.slice(0,3));
      }else{
        setImages(content);
      }
    }else{
      if(content && content.length > 7 && type !== 'categories') {
        setImages(content.slice(0,7));
      }else{
        setImages(content);
      }
    }
  }

  React.useEffect(() => {
    init();
  },[content,deviceType]);

  
  return (
    <Box
      sx={{
        position:'relative'
      }}
    >
      <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
        sx={{
          width:'100%',
          gap:'10px',
          p:'0px 0px',
          "&>>ul>li>button":{
            bottom:'-6px'
          },
          "&>ul>li>button":{
            backgroundColor:'#000',
            boxShadow:'inset 0px 0px 2px solid #fff'
          },
          "&>ul>.react-multi-carousel-dot--active>button":{
            backgroundColor:'#00FD90',
            boxShadow:'inset 0px 0px px solid #fff'
          }
        }}
        component={Carousel}
        ssr={false}
        partialVisibilityGutter 
        deviceType={deviceType}
        responsive={responsive}
        arrows={false}
        showDots={true}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      > 
        {
          images.slice(0,images.length>10?10:images.length).map((item,index)=>{
            return (
              <React.Fragment key={index}>
                {
                  type === 'categories' ?
                  <CardCategories item={item} width={width} index={index} />
                  :
                  <React.Fragment>
                    {
                      index <= 6 &&
                      <React.Fragment>
                        {
                          type === 'auction' &&
                          <CardAuction item={item} width={width} index={index} isYour={false} />
                        }
                        {
                          type === "nft" &&
                          <CardNFT item={item} width={width} index={index} />
                        }
                        {
                          type === 'profile' &&
                          <CardProfile item={item} width={width} index={index} />
                        }
                        {
                          type === 'curators' &&
                          <CardCurators item={item} width={width} index={index} />
                        }
                        {
                          type === 'collections' &&
                          <CardCollection item={item} width={width} index={index} />
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </React.Fragment>
            )
          })
        }
      </Grid>
    </Box>
  );
};

/* A prop type checker. It is used to check the type of the props passed to the component. */

CarouselSimple.propTypes = {
    deviceType: PropTypes.string,
    content: PropTypes.array,
    width: PropTypes.number,
    type: PropTypes.string,
    query: PropTypes.string
}

export default CarouselSimple;