import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch';
import SectionNFTs from './components/SectionNFTs';
import CollectionHeader from './components/CollectionHeader';
import LoaderCircle from 'components/LoaderCircle';
import ErrorMessage from 'components/ErrorMessage';
import TabSelector from 'components/TabSelector';
import LoaderNFT from 'components/LoaderNFT';
import Description from './components/Description';
import { useTranslation } from 'react-i18next';
import OfferHistory from 'components/OfferHistory';

const Collection = () => {
    const { t } = useTranslation("translate")
    const [activeTab, setActiveTab] = useState(1)
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const address = query.get("address")
    const project = `${process.env.REACT_APP_URL_API}/collection?address=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=10000&page=0&order=created`
    const nft = `${process.env.REACT_APP_URL_API}/nft?address=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=10000&page=0&order=created`
    const historyUrl = process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&contract_address=${address}&blockchain=${process.env.REACT_APP_NETWORK_NAME}&limit=10000&page=0&order=created`
    const {loading, error, data} = useFetch(project)
    const {loading:loadingNFTs, error:errorNfTs, data:NFTsData} = useFetch(nft)
    const {data:dataHistory, error:errorHistory, loading:loadingHistory} = useFetch(historyUrl)

    const initialState = [
        {
            name: t('collection.nft_tab'),
            active: true,
            number: 1,
        },
        // {
        //     name: t('collection.description_tab'),
        //     active: false,
        //     number: 2,
        // },
        {
            name: t('collection.activity_tab'),
            active: false,
            number: 3,
        }
    ]
    useEffect(() => {
        let customScroll = document.querySelector(".custom-scroll")
        if(customScroll){
            customScroll.scrollTo(0,0)
        }
        window.scrollTo(0,0)
    },[]);

    useEffect(()=>{
        if(!address){
            window.location.href = '/'
        }
    },[])

 
    if(loading){
        return (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                <LoaderCircle text={t('message_loader.collection.loading')} />
            </Box>
        )
    }
    return (
        <Box>
            <Container maxWidth="xl" sx={{ color: '#fff' }}>
                {error && <ErrorMessage error={error} />}
                { console.log("DATA",data)}
                {data && 
                    <CollectionHeader 
                        content={data[0]} 
                        address={address} 
                    />
                }
                <TabSelector items={initialState} setUpdate={setActiveTab} showSelector={true} activeTab={activeTab} t={t} />
                <Box sx={{width:'100%',minHeight:'300px'}}>
                    {activeTab === 1 && loadingNFTs && <LoaderNFT />}
                    {activeTab === 1 && errorNfTs && !loadingNFTs &&  <ErrorMessage error={errorNfTs} />}
                    {activeTab === 1 && !loadingNFTs && !errorNfTs  && <SectionNFTs content={NFTsData} NFTLoading={loadingNFTs} />}

                    {activeTab === 2 && loading && <Box display="flex" alignItems="center" justifyContent="center" sx={{minHeight: '200px',fontFamily:'BentonSansBBVA-Medium,sans-serif',color:'#fff'}} >
                        <h3>{t("collection.loading_description")}...</h3>
                    </Box>}
                    {activeTab === 2 && error && <ErrorMessage error={error} />}
                    {activeTab === 2 && data && <Description content={data[0].description}  />}
                    
                    {activeTab === 3 && loadingHistory && <Box display="flex" alignItems="center" justifyContent="center" sx={{minHeight: '200px',fontFamily:'BentonSansBBVA-Medium,sans-serif',color:'#fff'}} >
                        <h3>{t("collection.loading_activity")}...</h3>
                    </Box>}
                    {activeTab === 3 && errorHistory && <ErrorMessage error={errorHistory} />}
                    {activeTab === 3 && dataHistory && <Box sx={{marginTop:'2rem'}}><OfferHistory content={dataHistory} /></Box>}
                </Box>
            </Container>
        </Box>
    )
}

export default Collection