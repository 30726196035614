import React, { useContext } from 'react'
import {Box ,Button} from '@mui/material';
import { FiShare } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Context } from 'hooks/WalletContext';
import PropTypes from 'prop-types';

/**
 * I have a function that returns a component. The component has a button that triggers a function. The
 * function is passed as a prop to the component. The function is triggered by the button. The function
 * is a function that triggers a function that is passed as a prop to the component. The function that
 * is passed as a prop to the component is a function that triggers a function that is passed as a prop
 * to the component. The function that is passed as a prop to the component is a function that triggers
 * a function that is passed as a prop to the component. The function that is passed as a prop to the
 * component is a function that triggers a function that is passed as a prop to the component. The
 * function that is passed as a prop to the component is a function that triggers a function that is
 * passed as a prop to the component. The function that is passed as a prop to the component is a
 * function that triggers a function that is passed as a prop to the
 * @returns A component that is being rendered.
 */
const Share = ({ setState,  content}) => {
    const { t } = useTranslation("translate");
    const { data } = useContext(Context);

    /**
     * It takes a string as an argument and returns true if the string is a valid HTTP or HTTPS URL,
     * and false if it is not.
     * @param string - The string to check.
     * @returns A function that takes a string as an argument and returns a boolean.
     */
    const isValidHttpUrl = (string) => {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    width:'100%',
                    minHeight:'140px',
                    display:'grid',
                    gridTemplateColumns:'1fr',
                    position:'relative'
                }}
            >
                <Box
                    sx={{
                        margin:'0 auto',
                        position:'relative',
                        boxSizing:'border-box',
                        padding:'2rem',
                        backgroundColor:'#081A43',
                        backdropFilter: 'blur(50px)',
                        display:'inline-flex',
                        gap:'2rem',
                        borderRadius:'8px',
                        boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.3)',
                        top: {xs: '-1rem',sm:'-3.3rem'},
                        '@media screen and (max-width: 768px)':{
                            flexWrap:'wrap',
                            justifyContent:'space-between',
                        },
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                    <Box>
                        <Box sx={{color:'#FFFFFF', fontSize:'20px', fontWeight:700,lineHeight:'19px',fontFamily:'BentonSansBBVA-Medium,sans-serif'}}>
                            {content.name}
                        </Box>
                        <Box sx={{color:'#FFFFFF', fontWeight:600, fontSize:'23px',textAlign:'center',mt:'10px'}}>{content.collection_of}</Box>
                    </Box>
                    <Box display="none">
                        <Box sx={{color:'#FFFFFF', fontSize:'18px', fontWeight:500,lineHeight:'19px'}}>{t('collection.box_item_three')}</Box>
                        <Box sx={{color:'#FFFFFF', fontWeight:700, fontSize:'23px'}}>{content.floor_price}</Box>
                    </Box>
                    {
                        content && content.external_url && isValidHttpUrl(content.external_url) &&
                        <Box>
                            <Box sx={{color:'#FFFFFF', fontSize:'23px', fontWeight:500,lineHeight:'19px'}}>External Url</Box>
                            <Box sx={{color:'#FFFFFF', fontWeight:700, fontSize:'23px'}}>
                                <Box
                                    component="a"
                                    href={content.external_url}
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    sx={{
                                        textDecoration: 'none',
                                        color:'#fff', fontWeight:700, fontSize:'23px',
                                        cursor: 'pointer',
                                        textAlign: 'center'
                                    }}
                                >
                                    Here
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box
                    sx={{
                        position:'absolute',
                        top:{xs:'0px',sm:'0px',md:'-35px'},
                        right:'3rem',
                        paddingRight:'2rem',
                        "@media (max-width:1000px)":{
                            position:'relative',
                            paddingRight:'0px',
                            right:'0px',
                        }
                    }}
                >
                    <Box 
                        sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            mt:{xs:'10px',sm:'0px'},
                            gap:'10px',
                            "@media (max-width:1000px)":{
                                justifyContent:'space-around',
                                mb:'15px'
                            }
                        }}
                    >
                        <Box 
                            component={Button}
                            onClick={()=>setState(true)}
                            sx={{
                                background:'#081A43',
                                padding:'1rem',
                                color:'#FEFFFF',
                                borderRadius:'6px',
                                "&:hover":{
                                    color:'#35F8FF',
                                    border:'1px solid #35F8FF',
                                    background:'#081A43'
                                },
                                fontFamily:'BentonSansBBVA-Medium,sans-serif'
                            }}
                        >
                            <span style={{marginRight:'5px'}}>{t('collection.share_btn')}</span>
                            <FiShare size={25} />
                        </Box>
                        {data && data.userAccount && String(data.userAccount+'').toUpperCase() == String(content.owner+'').toUpperCase() &&  (
                            <Box 
                                component={Link}
                                to={`/create/edit-collection?project_key=${content.project_key}`}
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                onClick={()=>setState(true)}
                                sx={{
                                    background:'#081A43',
                                    padding:'1rem',
                                    color:'#FEFFFF',
                                    borderRadius:'6px',
                                    "&:hover":{
                                        color:'#35F8FF',
                                        border:'1px solid #35F8FF',
                                        background:'#081A43'
                                    },
                                    fontFamily:'BentonSansBBVA-Medium,sans-serif',
                                    textDecoration:'none',
                                    fontWeight:'500'
                                }}
                            >
                                <span style={{marginRight:'5px'}}>{t('collection.edit')}</span>
                                <EditIcon size={25} />
                            </Box>
                    )}
                    </Box>
                    
                   
                </Box>
            </Box>
        </React.Fragment>
    )
}

Share.propTypes = {
    content: PropTypes.object.isRequired,
    func: PropTypes.func,
    setState: PropTypes.func,
    onChange: PropTypes.func,
    fileRef: PropTypes.object,
}

export default Share