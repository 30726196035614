import React from 'react';
import blockies from 'ethereum-blockies-base64';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar'

const IconBlockies = ({ variant, address='', size = 8, scale = 4, sx={} }) => {
  const src = blockies(address, { size, scale });
  
   // <img src={src} alt="blockie" style={{ borderRadius: '50%' }} />;
  return <Avatar variant={variant} src={src} sx={sx} />
};

IconBlockies.defaultProps = {
  variant: "square",
  address: "",
  size: 8,
  scale: 4,
  sx:{}
}

IconBlockies.propTypes = {
  variant: PropTypes.string,
  address: PropTypes.string,
  size: PropTypes.number,
  scale: PropTypes.number,
  sx: PropTypes.object
}

export default IconBlockies;
