import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const ButtonBBVA = styled(Button)`
    background-color: #028484;
    font-size: 15px;
    font-weight:600;
    border-radius: 3px 3px;
    font-family: BentonSansBBVA-Medium,Helvetica,Arial,sans-serif;
    padding: 14px 24px;
    &:hover {
        background-color: #02a5a5; 
    } 
    @media (max-width: 1024px) {
        font-size: 10px;
        padding: 10px 5px;
    }
`;

export const ButtonGoToBBVA = styled(Button)`
    margin-left: 130px;
    padding: 0px 0px;
    height: calc(60% - 0px);
    background-color: transparent;
    color: #f8cd51;
    font-size: 14px;
    font-weight: 600;
    line-height: 88px;
    font-family: BentonSansBBVA-Medium,sans-serif;
    &:hover {
        background-color: transparent;
        color: #fff;
    }
    @media (max-width: 1024px) {
        font-size: 11px;
    }
    @media (max-width: 600px) {
        display:none
    }
`;