
import React from 'react';
import { Box, CardMedia } from '@mui/material';
import { ImageContainer, LinkUserProfile } from './styled';
import PropTypes from 'prop-types';
import IconBlockies from 'components/IconBlockies/IconBlockies';

const CardNFT = ({ item }) =>{

    return (
        <ImageContainer>
           {
            item && item.metadata.is_video  ?
            <CardMedia
                className={'card-collection'}
                component={'video'}
                src={item && item.thumb_url}
                autoPlay
                loop
                muted
                sx={{
                    position:'relative',
                    borderRadius: '8px',
                    height:'100%',
                    width:'100%',
                    margin:'0 auto'
                }}
            />
            :
            <CardMedia
                className={'card-collection'}
                component={'img'}
                src={item && item.thumb_url}
                sx={{
                    position:'relative',
                    borderRadius: '8px',
                    height:'100%',
                    width:'100%',
                    margin:'0 auto',
                }}
            />
        }
        <Box
            sx={{
                position:'absolute',
                width:'100%',
                height:'calc(100% + 1px)',
                borderRadius:'0px 0px 8px 8px',
                top:'0px',
                left:'0px',
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 25.53%, rgba(0, 0, 0, 0.5) 67.17%, #000000 109.78%)'
            }}
        />
        <Box sx={{position:'absolute',left:'0px',bottom:'0px'}}>
            <Box padding="0px 0px 20px 20px">
                <LinkUserProfile to={`/profile?address=${item.user.main_key}`} >
                    <Box display="inline-flex" gap="10px" alignItems="center" >
                        <IconBlockies address={item?.user?.main_key} />
                        <Box 
                            sx={{
                                display:'none',
                                fontFamily:'BentonSansBBVA-Medium,sans-serif'
                            }}
                        >
                            {item.user.short_main_key}
                        </Box>
                    </Box>
                </LinkUserProfile>
            </Box>
        </Box>
        </ImageContainer>
    );
};

CardNFT.propTypes = {
    item: PropTypes.object
}

export default CardNFT;
