import React from 'react'
import { CircularProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * It's a function that returns a circular progress bar with a text underneath it.
 * @param {string} text The text to draw
 * @param {string} textColor The color text should be
 * @param {string} spinnerColor The color spinner should be
 * @returns A component that is a circular progress bar with a text.
 */
const LoaderCircle = ({text, textColor, spinnerColor}) => {
    return(
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
                textAlign: 'center',
                marginTop: '2rem',
                color: textColor ? textColor : '#000',
                fontFamily:'BentonSansBBVA-Medium,sans-serif'
            }}
        >
            <CircularProgress
                sx={{
                    color: spinnerColor ? spinnerColor : '#000',
                }}
            />
            <h3>{text}...</h3>
        </Box>
    )
}

LoaderCircle.defaultProps = {
    textColor:'#FFFF',
    spinnerColor:'#FFFF'
}

LoaderCircle.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    spinnerColor: PropTypes.string,
}

export default LoaderCircle;