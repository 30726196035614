import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'


const defaultArray = [1, 2, 3,4];

const LoaderCollection = () => {


    return (
        <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{sm:12, md:12, lg:12, xl:12}}
                rowSpacing={4} 
                spacing={{ xs: 1,sm:2, md: 3, lg: 3, xl: 3 }}
            >
                {defaultArray.map((item, index)=>(
                <Grid 
                    key={index} 
                    item 
                    sm={12} 
                    md={6} 
                    lg={3} 
                    xl={3}
                    sx={{
                        width:'100%',
                        display:{xs:index == 0 ? 'grid':'none',sm: index == 0 ? 'grid':'none',md: index == 0 || index == 1 ? 'grid':'none',lg:'grid',xl:'grid'}
                    }}
                >
                    <Skeleton variant="rectangular" height='380px' width="100%" sx={{maxHeight:'380px',background:'rgba(0,0,0,.5)'}}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                </Grid>))}
            </Grid>
        </Container>
    )
}


export default LoaderCollection