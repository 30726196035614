import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NFTs from './components/NFTs';
import { useFetch } from 'hooks/useFetch';
import {Link} from 'react-router-dom';
import LoaderCircle from 'components/LoaderCircle';
import ErrorMessage from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';


import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
/* imports para carousel */
import "react-multi-carousel/lib/styles.css";
import CarouselSimple from 'components/Carousel/CarouselSimple';
import { Typography } from '@mui/material';


const handleResize = (size) => {
    if(size <= 600){
        return "mobile";
    }else
    if(size > 600 && size < 1024){
        return "tablet";
    }else
    if(size > 1023 && size < 3000){
        return "desktop"
    }else
    if(size >= 3000){
        return "big";
    }
}

const Collections = () => {
    const { t } = useTranslation("translate");
    const url = `${process.env.REACT_APP_URL_API}/collection?domain=${process.env.REACT_APP_DOMAIN}&limit=300&page=0&order=created`;
    const { data: projects, error, loading } = useFetch(url);
    const limitNFTRef = React.useRef(6);
    const pageNftRef = React.useRef(0);
    const urlNft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created`
    const { data: nftData, error:errorNFt, loading:loadingNFT} = useFetch(urlNft);
    let width = window.innerWidth;
    const [typeDevice,setTypeDevice] = React.useState(handleResize(width));

    React.useEffect(() => {
        window.addEventListener("resize", function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
    
        window.removeEventListener("resize",function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
    },[width]);

    return (
        <Container maxWidth="xl">
            <Box 
                sx={{width:'100%',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '32px',
                    lineHeight: '100%',
                    color:'#FFFFFF',
                    mt:'50px',
                    mb:'20px',
                    textTransform:'uppercase'
                }}
            >
                {t("collections.collections")}
            </Box>
            {
                loading && <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '90vh' }}>
                <LoaderCircle text={t("collection_buy_view.loading")} />
                </Box>
            }
            {
                error && !loading &&
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: '90vh' }}>
                    <ErrorMessage error={error} />
                </Box>
            }
            {
                !error && !loading && projects &&
                <React.Fragment>
                    <CarouselSimple 
                        deviceType={typeDevice}
                        content={projects}
                        width={5555} 
                        type={"collections"} 
                    />
                </React.Fragment>
            }
            {
                !error && !loading && projects &&
                <Box sx={{display:'grid',width:'100%',gridTemplateColumns:'repeat(1,1fr)',mt:'50px'}}>
                    {
                        !errorNFt && nftData && nftData.length > 0  && !loadingNFT &&
                        <Box
                            sx={{width:'100%',display:'flex',justifyContent:'space-between'}}
                        >
                            <Box 
                                sx={{
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '100%',
                                    color:'#FFFFFF',
                                    "@media screen and (max-width:430px)":{
                                        fontSize: '18px',
                                    }
                                }}>
                                {t("collections.featured_collectibles")}
                            </Box>
                            <Box 
                                sx={{
                                    display:'flex',alignItems: 'center',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '100%',
                                    color:'#FFFFFF',
                                    opacity: 0.5,
                                    cursor:'pointer',
                                    "&:hover":{
                                        opacity: 1
                                    },
                                    "@media screen and (max-width:430px)":{
                                        fontSize: '15px',
                                    }
                                }}>
                                    <Link to={"/marketplace"} style={{textDecoration:'none',color:'#fff'}}> {t("collections.see_more")}</Link>
                            </Box>
                        </Box>
                    }
                    <Box sx={{width:'100%',mt:'20px'}}>
                        {
                            !errorNFt && nftData && !loadingNFT && nftData.length > 0 && nftData.filter((nft) => nft.transaction == "PRE_MINT").length == 0 &&
                            <NFTs content={nftData} />
                        }
                        {
                            !errorNFt && nftData && !loadingNFT && nftData.length > 0 &&
                            nftData.filter((nft) => nft.transaction == "PRE_MINT").length > 1 && 
                            <Container maxWidth="md" sx= {{ mt: 5 }}>
                                <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >
                                    {t("nft-screen.nft_not_available")}
                                </Typography>
                            </Container>
                        }
                        {/* {
                            !errorNFt && nftData && !loadingNFT && !error && !loading && nftData.length == 0 &&
                            <Container maxWidth="md" sx= {{ mt: 5 }}>
                                <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >
                                    {t("nft-screen.nft_not_available")}
                                </Typography>
                            </Container>
                        } */}
                        {
                            !errorNFt && loadingNFT && 
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <LoaderCircle text={t("collection_buy_view.loading")} />
                            </Box>
                        }
                        {
                            !loadingNFT && errorNFt && 
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <ErrorMessage error={errorNFt} />
                            </Box>
                        }
                    </Box>
                </Box>
            }
            
        </Container>
    )
}

export default Collections;