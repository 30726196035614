import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ClaimButtonContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ClaimButton = styled(Button)`
    border: 2px solid #2DCCCD;
    background-color: transparent;
    color: #2DCCCD;
    width: 100px;
    &:hover {
        background-color: #2DCCCD;
        color: #061D3C;
    }
`;

export const ImageContainer = styled(Box)`
    width: 100%;
    position: relative;
    height: 600px;
    @media (max-width: 1200px) {
        height: 500px;
    }
    @media (max-width: 800px) {
        height: 500px;
    }
    @media (max-width: 600px) {
        height: 400px;
    }
    @media (max-width: 380px) {
        height: 300px;
    }
`;

export const LinkUserProfile = styled(Link)`
    color: #fff;
    text-decoration: none;
`;