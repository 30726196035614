import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const ContainerCollectionBuy = styled(Box)`
    padding-bottom: 50px;
    min-height: calc(100vh - 120px);
`;

export const CollectionSoldOut = styled(Box)`
    /* 
    sx={{
    textAlign: 'center',
    fontSize: {xs: '38px', sm: '60px', md: '80px', lg: '100px', xl: '110px' },
    fontWeight: '600', 
    flex: 1,
    letterSpacing: {xs: '2px', sm: '10px', md: '30px', lg: '30px', xl: '30px' },
    fontFamily:'BenotonSansBBVA-Bold,sans-serif '
}} */
    text-align: center;
    font-size: 38px;
    font-weight: 600;
    flex: 1;
    letter-spacing: 2px;
    font-family: BenotonSansBBVA-Bold, sans-serif;
    @media (min-width: 600px) {
        font-size: 60px;
        letter-spacing: 10px;
    }
    @media (min-width: 960px) {
        font-size: 80px;
        letter-spacing: 30px;
    }
    @media (min-width: 1280px) {
        font-size: 100px;
        letter-spacing: 30px;
    }
    @media (min-width: 1920px) {
        font-size: 110px;
        letter-spacing: 30px;
    }
`;

export const CollectionBuyButtonLink = styled(Button)`
    max-width: 160px;
    margin-top: 24px;
    min-width: 97px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 56px;
    color: #feffff;
    border-radius: 6px 6px;
    border: 2px solid #002e7b;
    font-family: 'BentonSansBBVA-Medium', sans-serif;
    @media (min-width: 600px) {
        display: none;
    }
`; 

export const CollectionBuyContent = styled(Box)`
    /* 
    flex:1,
    width:"100%",
    display:'flex',
    justifyContent:"space-between",
    alignItems:"flex-end",
    "@media screen and (max-width:400px)":{
        display:'grid',
        gridTemplateColumns:'1fr'
    } */
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 400px) {
        display: grid;
        grid-template-columns: 1fr;
    }
`; 

export const ContainerUserName = styled(Box)`
    /* 
    display: { xs: 'initial', sm: 'initial', md: 'none', lg: 'none', xl: 'none' },
    color:'#081A43',
    fontFamily:'BenotonSansBBVA-Bold,sans-serif ' */
    display: initial;
    color: #081a43;
    font-family: BenotonSansBBVA-Bold, sans-serif;
    @media (min-width: 600px) {
        display: initial;
    }
    @media (min-width: 960px) {
        display: none;
    }
    @media (min-width: 1280px) {
        display: none;
    }
    @media (min-width: 1920px) {
        display: none;
    }
`;

export const ContainerUserNameResponsive = styled(Box)`
/* 
sx={{
        display: { xs: 'none', sm: 'none', md: 'initial', lg: 'initial', xl: 'initial' },
        color:'#081A43',
        fontWeight:'bold',
        fontFamily:'BenotonSansBBVA-Bold,sans-serif '
    }} */
    display: none;
    color: #081a43;
    font-weight: bold;
    font-family: BenotonSansBBVA-Bold, sans-serif;
    @media (min-width: 600px) {
        display: none;
    }
    @media (min-width: 960px) {
        display: initial;
    }
    @media (min-width: 1280px) {
        display: initial;
    }
    @media (min-width: 1920px) {
        display: initial;
    }
`;

export const ContainerCardInfo = styled(Box)`
    /* 
    width: '100%',
    display:{xs:'flex',sm:'grid'},
    gridTemplateColumns:{xs: 'repeat(1,1fr)', sm: 'repeat(1,1fr)', md: 'repeat(2,1fr)' } ,
    flexDirection:'column-reverse',
    gap:'30px' */
    width: 100%;
    display: flex;
    padding: 0px 0px;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column-reverse;
    gap: 30px;
    margin: 0px 0px;
    @media (min-width: 600px) {
        padding: 0px 15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1920px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ContentCardInfo = styled(Box)`
    /* padding:{xs:'0px 0px',sm:'15px 15px'},
        minHeight: {xs:'200px',sm:'400px'},
        background:{xs:'transparent',sm:'#FEFFFF'},
        boxShadow:'none' */
    padding: 0px 0px;
    min-height: 200px;
    background: transparent;
    box-shadow: none;
    @media (min-width: 600px) {
        min-height: 400px;
        box-shadow: none;
    }
`;

export const ContentCardUserName = styled(Box)`
    /* 
    display: { xs: 'none', sm: 'initial', md: 'initial', lg: 'initial', xl: 'initial'},
    fontSize: '30px',
    fontWeight:'bold',
    fontFamily:'BenotonSansBBVA-Bold,sans-serif' */
    display: none;
    font-size: 36px;
    font-weight: bold;
    font-family: 'BentonSansBBVA-Medium',sans-serif;
    @media (min-width: 600px) {
        display: initial;
    }
    @media (min-width: 960px) {
        display: initial;
    }
    @media (min-width: 1280px) {
        display: initial;
    }
    @media (min-width: 1920px) {
        display: initial;
    }
`;

export const ContainerCardTotal = styled(Box)`
    /* 
    display:{xs:'none',sm:'flex'},
    fontSize: '17px',
    mb: 2,
    mt: { xs: 8, sm: 0, md: 0, lg: 0, xl: 0 },
    fontFamily:'BentonSansBBVA-Medium,sans-serif ' */
    display: none;
    font-size: 17px;
    margin-bottom:15px;
    margin-top: 8;
    min-height:25px;
    font-family: 'BentonSansBBVA-Medium', sans-serif;
    @media (min-width: 600px) {
        display: flex;
    }
    @media (min-width: 960px) {
        display: flex;
    }
    @media (min-width: 1280px) {
        display: flex;
    }
    @media (min-width: 1920px) {
        display: flex;
    }
`;

export const ContainerCardPublicMint = styled(Box)`
    /* 
    background:{xs:'#FEFFFF',sm:'transparent'},
    boxShadow:'none',
    padding:{xs:'15px 15px',sm:'0px 15px',md:'0px 15px',lg:'0px 15px',xl:'0px 15px'},
    mt:{xs:'24px',sm:'0px',md:'0px',lg:'0px',xl:'0px'} */
    background: transparent;
    box-shadow: none;
    padding: 0px 0px;
    margin-top: 24px;
    @media (min-width: 600px) {
        box-shadow: none;
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 960px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 1280px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 1920px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
`;

export const ButtonViewCollection = styled(Button)`
    /* 
    display:{xs:'none',sm:'flex'},
    maxWidth:'160px',
    mt: '24px',
    minWidth: '97px',
    height: '56px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '56px',
    color:'#FEFFFF',
    borderRadius: '6px 6px',
    border:'2px solid #002E7B',
    fontFamily:'BenotonSansBBVA-Bold,sans-serif',
    "&.MuiButton-endIcon,svg":{
        background:'#FEFFFF'
    },
    "&:hover":{
        background:colorBtn,
        color:'#35F8FF',
        "&.MuiButton-endIcon,svg":{
            background:'#35F8FF'
        }
    }, */
    display: none;
    max-width: 160px;
    margin-top: 24px;
    min-width: 97px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 56px;
    color: #feffff;
    border-radius: 6px 6px;
    border: 2px solid #002e7b;
    font-family: 'BentonSansBBVA-Medium', sans-serif;
    @media (min-width: 600px) {
        display: flex;
    }
    @media (min-width: 960px) {
        display: flex;
    }
    @media (min-width: 1280px) {
        display: flex;
    }
    @media (min-width: 1920px) {
        display: flex;
    }
`;

export const DescriptionText = styled(Box)`
    word-wrap: break-word;
    color:#fff;
    word-spacing:2px;
    text-align:left;
    b,span{
        word-wrap: break-word;
    }
    &{
        word-wrap: break-word;
    }
`;