import React, { useEffect, useState }  from 'react';
import { Box, CardMedia, Container } from '@mui/material';
import BBVALogo from 'assets/logos/BBVA_logo.svg';
import { footer } from 'api/sanity';
import { FooterContainer, FooterContent, FooterContentLinks, FooterContentLogo } from './styled';
import BlockContent from '@sanity/block-content-to-react';

const Footer = () => {
    const [footerSanitySocial, setFooterSanitySocial] = useState([]);
    const [footerSanityText, setFooterSanitytext] = useState([]);
    useEffect(()=>{
        footer()
            .then((response) => {
                setFooterSanitySocial(response[0].socialIcon);
                setFooterSanitytext(response[0].es.footerText);
                console.log(response[0]);
            })
            .catch((error) => {
                console.log(error);
            })
    },[]);
    const handleClickSocialMedia = (link) =>{
        window.open(link, '_blank');
    };
   
    return (
        <FooterContainer>
            <Container maxWidth="xl">
                <FooterContent>
                    <FooterContentLogo>
                        <CardMedia
                            component="img"
                            src={BBVALogo}
                            alt="bbva-logo"
                            sx={{ width: {xs:'120px',sm:'96px',md:'96px',lg:'96px',xl:'96px'} }}
                        />
                    </FooterContentLogo>
                    
                    <Box />
                    <FooterContentLinks>
                        <Box display="flex" gap="10px" alignItems="center" 
                            sx={{
                                mt:{xs:'10px',sm:'0px'}
                            }}>
                            {footerSanitySocial && footerSanitySocial.map((item, index) => (
                                <Box key={index} display="flex" gap="5px" alignItems="center" 
                                    sx={{
                                        border:'0px 0px',
                                        borderRadius: '50%', 
                                        backgroundColor: '#5bbeff',
                                        '&:hover': {
                                            backgroundColor: '#FEFFFF'
                                        },
                                        padding:'2px'
                                    }}>
                                    <CardMedia
                                        onClick={() => handleClickSocialMedia(item.link)} 
                                        component="img"
                                        src={item.social.asset.url}
                                        alt={item.social.asset._id}
                                        sx={{
                                            border:'0px 0px',
                                            width: {xs:'32px',sm:'24px'},
                                            height: {xs:'32px',sm:'24px'},
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            filter: 'hue-rotate(11deg) saturate(100%) brightness(40%)'
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </FooterContentLinks>
                </FooterContent>
            </Container>
            <Container maxWidth="xl">
                {footerSanityText && (
                    <Box sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},justifyContent:{xs:'center',sm:'space-between'}}}>
                        <Box sx ={{
                            fontFamily: 'BentonSansBBVA, sans-serif',
                            textAlign: {xs:'center',sm:'left'},
                            fontSize: '12px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: {xs:'100%',sm:'calc(99% - 170px)'},
                            maxWidth:'800px',
                            color:'#BDBDBD',
                            "&>p":{
                                color:'#BDBDBD',
                            }
                        }}>
                            <BlockContent blocks={footerSanityText} projectId="10hflvbp" />
                        </Box>
                        <Box sx={{display:'flex',alignItems:'center',color:'#2dcccd',fontSize:'15px',minWidth:'170px',fontFamily:'BentonSansBBVA-Medium,sans-serif',justifyContent:{xs:'center',sm:'flex-end'}}}>        
                            Creando Oportunidades
                        </Box>
                    </Box>
                )}
            </Container>
        </FooterContainer>
    );
};

export default Footer;
